import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['haStatusId', 'certificateOther']

  connect() {
    const scope = this.data.get("scope")
    this.hasHaStatusIdTarget && this.haStatusIdTarget.addEventListener("change", function (e) {
      var hasHa = e.target.value === '1'
      // var haCertifiedDate3iElement = document.getElementById(`${scope}_ha_certified_date_3i`)
      // var haCertifiedDate2iElement = document.getElementById(`${scope}_ha_certified_date_2i`)
      // var haCertifiedDate1iElement = document.getElementById(`${scope}_ha_certified_date_1i`)
      // var haExpireDate3iElement = document.getElementById(`${scope}_ha_expire_date_3i`)
      // var haExpireDate2iElement = document.getElementById(`${scope}_ha_expire_date_2i`)
      // var haExpireDate1iElement = document.getElementById(`${scope}_ha_expire_date_1i`)
      document.getElementsByName("ha-date").forEach(haDateElement => {
        haDateElement.style.display = hasHa ? 'none' : 'block'

        if (haDateElement.querySelector('.flatpickr-input')) {
          haDateElement.querySelector('.flatpickr-input').required = hasHa ? false : true
        }
      })
      // haCertifiedDate1iElement.disabled = haCertifiedDate2iElement.disabled = haCertifiedDate3iElement.disabled = hasHa
      // haExpireDate1iElement.disabled = haExpireDate2iElement.disabled = haExpireDate3iElement.disabled = hasHa
    });
    this.hasCertificateOtherTarget && this.certificateOtherTarget.addEventListener("change", function (e) {
      var hasCertificate = e.target.checked
      var certificateElement = document.getElementById(`${scope}_certificate`)
      // var certCertified3iElement = document.getElementById(`${scope}_cert_certified_date_3i`)
      // var certCertified2iElement = document.getElementById(`${scope}_cert_certified_date_2i`)
      // var certCertified1iElement = document.getElementById(`${scope}_cert_certified_date_1i`)
      // var certExpire3iElement = document.getElementById(`${scope}_cert_expire_date_3i`)
      // var certExpire2iElement = document.getElementById(`${scope}_cert_expire_date_2i`)
      // var certExpire1iElement = document.getElementById(`${scope}_cert_expire_date_1i`)
      certificateElement.value = null
      // certCertified3iElement.value = certCertified2iElement.value = certCertified1iElement.value = null
      // certExpire3iElement.value = certExpire2iElement.value = certExpire1iElement.value = null
      certificateElement.required = hasCertificate
      // certCertified3iElement.required = hasCertificate
      // certCertified2iElement.required = hasCertificate
      // certCertified1iElement.required = hasCertificate
      // certExpire3iElement.required = certExpire2iElement.required = certExpire1iElement.required = hasCertificate
      document.getElementsByName("certificate-data").forEach(certificateDataElement => {
        certificateDataElement.style.display = hasCertificate ? 'block' : 'none'

        if (certificateDataElement.querySelector('.flatpickr-input')) {
          certificateDataElement.querySelector('.flatpickr-input').required = hasCertificate ? true : false
        }
      })
    });
  }
}
