import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['subTree', 'trigger', 'checkbox']

  connect() {
    const nodeArray = (selector, parent = document) => Array.prototype.slice.call(parent.querySelectorAll(selector))
    this.checkboxTargets.forEach(check => {
      this.parentCheck(nodeArray, check)
    })

    if (!Boolean(this.data.get('hide'))) {
      document.querySelectorAll('.treeview').forEach(el => {
        el.classList.add('treeview--active')
      })

      document.querySelectorAll('.treeview__item').forEach(el => {
        el.classList.add('treeview__item--active')
      })
    }
  }

  onClickCheckbox(e) {
    const nodeArray = (selector, parent = document) => Array.prototype.slice.call(parent.querySelectorAll(selector))
    let check = e.target
    let container = check.parentElement.parentElement.nextElementSibling && check.parentElement.parentElement.nextElementSibling.children

    if (container) {
      for (let item of container) {
        let checkboxs = item.querySelectorAll('input[type="checkbox"]')
        for (let checkbox of checkboxs) {
          if (!checkbox.disabled) {
            checkbox.checked = check.checked
          }
        }
      }
    }

    this.parentCheck(nodeArray, check)
  }

  parentCheck(nodeArray, check) {
    const parent = check.closest('ul').parentNode.querySelector('input[type="checkbox"]')
    const siblings = nodeArray('input[type="checkbox"]', parent.closest('li').querySelector('ul'))

    const checkStatus = siblings.map(check => check.checked)
    const every = checkStatus.every(Boolean)
    const some = checkStatus.some(Boolean)

    parent.checked = every
    parent.indeterminate = !every && every !== some
    check = check != parent ? parent : 0
  }

  toggle(e) {
    e.target.parentElement.parentElement.classList.toggle('treeview__item--active')
    e.target.parentElement.nextElementSibling.classList.toggle('treeview--active')
  }
}
