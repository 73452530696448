export function showErrorDialog(errCode) {
  switch (errCode) {
    case 500:
      alert({
        title: I18n.t("error.title"),
        content: I18n.t("error.server_error_try_again"),
        type: "alert",
        style: "danger",
      });
      break;
    default:
      alert({
        title: I18n.t("error.title"),
        type: "alert",
        style: "danger",
      });
      break;
  }
}
