import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['saveButton', 'select', 'payment']

  connect() {
    this.saveButtonTarget.style.display = 'none'
    this.selectTarget.addEventListener("change", () => this.saveButtonTarget.style.display = 'block');
    this.paymentTarget.addEventListener("change", () => this.saveButtonTarget.style.display = 'block');
  }
}
