import { Controller } from 'stimulus'
import debounce from 'debounce'

export default class extends Controller {
  static targets = [ 'input', 'form', 'submit' ]
  
  connect() {
    this.inputTarget.addEventListener('keyup', debounce(() => {
      this.submitTarget.click()
    }, 500))
  }
}
