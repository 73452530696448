import { Controller } from "stimulus";
import { api as apiHelper } from "../helper";

export default class extends Controller {
  static targets = ["editable", "container"];

  handleActiveEdit() {
    this.containerTarget.classList.add("note--active");
  }

  handleDeactivateEdit() {
    this.containerTarget.classList.remove("note--active");
    const formData = new FormData();
    formData.append(
      "kpi_sheet_input_note[note]",
      this.containerTarget.innerText
    );

    fetch(this.data.get("url"), {
      method: "POST",
      headers: apiHelper.apiHarder(),
      body: formData,
    });
  }
}
