import { Controller } from "stimulus";
import { api as apiHelper } from "../helper";

export default class extends Controller {
  static targets = ["masterCheckbox", "checkbox"];

  // onMasterCheck (e) {
  //   this.checkboxTargets.forEach((checkbox) => {
  //     checkbox.checked = e.target.checked
  //   })
  // }

  onCheck(e) {
    // const checkStatus = this.checkboxTargets.map(check => check.checked)
    // const every = checkStatus.every(Boolean)
    // const some = checkStatus.some(Boolean)
    this.updateStatus(e.target);
  }

  updateStatus(target) {
    let url = this.data
      .get("url")
      .replace(":id", target.getAttribute("data-id"));
    fetch(url, {
      method: "post",
      headers: apiHelper.apiHarderCustom({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ checked: target.checked }),
    })
      .then((response) => response.text())
      .then((data) => eval(data));
  }
}
