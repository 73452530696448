import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ['chatList', 'input', 'submit']

  connect() {
    console.log("create channel message")
    this.subscription = consumer.subscriptions.create(
      {
        channel: 'MessagesChannel',
        room: this.data.get('hospitalId')
      },
      {
        received: this._received.bind(this)
      }
    )
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription)
  }

  _received(data) {
    if (data.event === 'message') {
      let html = `
        <div class="chat__item ${data.current_user.id === data.from_user.id ? 'chat__item--self' : ''}">
          <div class="chat__item__header">
            <div class="chat__item__image"></div>
            <div class="chat__item__title">${data.from_user.first_name_th} ${data.from_user.last_name_th}</div>
          </div>
          <div class="chat__item__content">
            ${data.message.message}
          </div>
          <div class="chat__item__meta">
            ${data.message.created_at}
          </div>
        </div>
      `
      let chatIcon = document.getElementById(`chatIcon${data.message.kpi_id}_${data.message.kpi_sheet_id}`)
      let chatList = document.getElementById(`chatList${data.message.kpi_id}_${data.message.kpi_sheet_id}`)
      if (chatIcon) { chatIcon.classList.contains("link--primary") || chatIcon.classList.add("link--primary") }
      if (chatList) {
        chatList.insertAdjacentHTML('beforeend', html)
        chatList.scrollTop = chatList.scrollHeight
      }
    } else {
      let noteIcon = document.getElementById(`noteIcon${data.message.kpi_template_id}_${data.message.sheet_id}`)
      let noteInput = document.getElementById(`noteInput${data.message.kpi_template_id}_${data.message.sheet_id}`)

      if (noteIcon) {
        if (data.message.note) {
          noteIcon.classList.add("link--primary")
        } else {
          noteIcon.classList.remove("link--primary")
        }
      }

      if (noteInput) {
        noteInput.textContent = data.message.note
      }
    }
  }
}
