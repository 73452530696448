import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "code", "name", "address", "size" ]

  search(event) {
    let query = this.inputTarget.value
    if (query === "") return

    fetch(`/hospitals/search.json?q=${query}`)
      .then(response => response.json())
      .then(data => {
        if (data.length == 0) {
          alert({ title: I18n.t("form.hospital_not_found"), type: 'alert', style: 'danger' })
          this.reset()
          return
        }

        let hospital = data[0]
        this.codeTarget.value = hospital.code
        this.nameTarget.textContent = hospital.name
        this.addressTarget.textContent = hospital.address
        this.sizeTarget.textContent = hospital.size
      })
  }

  reset() {
    this.codeTarget.value = ""
    this.nameTarget.textContent = ""
    this.addressTarget.textContent = ""
    this.sizeTarget.textContent = ""
  }
}
