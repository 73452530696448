import { changeElementsDisplayStyle } from "./utils";

// this อันนี้คือต้อง bind เข้ากับ this ของ report controller
export function togglePeriodType(value) {
  if (value !== "0") {
    changeElementsDisplayStyle(this.datepickerContainerTargets, "none");
    changeElementsDisplayStyle(this.yearSelectContainerTargets, "block");
  } else {
    changeElementsDisplayStyle(this.datepickerContainerTargets, "block");
    changeElementsDisplayStyle(this.yearSelectContainerTargets, "none");
  }
}
