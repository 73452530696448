import { Controller } from "stimulus";
import Trix from "trix";
import YoutubeEmbedded from "embed";
import { api as apiHelper } from "../helper";
export default class extends Controller {
  static targets = ["field"];

  connect() {
    this.editor = this.fieldTarget.editor;
    // this.addEmbedButton(this.fieldTarget)
    let embed = new YoutubeEmbedded();
    embed.extend(this.fieldTarget);

    document.addEventListener("add-embed", (event) => {
      let link = event.detail.link();
      console.log(link);
      this.createEmbededLink(link);
    });
  }

  createEmbededLink(link) {
    if (link) {
      fetch("/embedded_links", {
        method: "POST",
        headers: apiHelper.apiHarderCustom({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          embedded_link: {
            link,
          },
        }),
      })
        .then((response) => response.json())
        .then(({ sgid, content, contentType }) => {
          console.log("hey!");
          const attachment = new Trix.Attachment({
            content,
            sgid,
            contentType,
            filename: link,
            previewable: true,
          });
          this.editor.insertAttachment(attachment);
          this.editor.insertLineBreak();
        });
    }
  }
}
