import { Controller } from "stimulus";
import AppearanceObserver from "../helper/appearanceObserver";

export default class extends Controller {
  static values = { src: String };
  initialize() {
    this.connected = false;
    this.appearanceObserver = new AppearanceObserver(this, this.element);
  }

  connect() {
    if (!this.connected) {
      this.connected = true;
      this.appearanceObserver.start();
    }
  }

  disconnect() {
    if (this.connected) {
      this.appearanceObserver.stop();
    }
  }

  elementAppearedInViewport(element) {
    this.loadSourceURL();
  }

  loadSourceURL() {
    this.element.src = this.srcValue;
    this.appearanceObserver.stop();
  }
}
