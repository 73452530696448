import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'formulaSelect',
    'stypeSelect',
    'kpiInput1',
    'kpiInput2',
    'kpiLevelTemplate1',
    'kpiLevelTemplate2',
    'form',
  ]

  connect() {
    this.formArr = Array.prototype.slice.call(this.formTarget.elements)
    this.initialKPIFormulaForm()
  }

  initialKPIFormulaForm() {
    this.kpiInput1Target.style.display = 'none'
    this.kpiInput2Target.style.display = 'none'
    this.kpiLevelTemplate1Target.style.display = 'none'
    this.kpiLevelTemplate2Target.style.display = 'none'

    this.checkKPIInput()
    this.checkKPILevelTemplate()
  }

  checkKPIInput() {
    if (this.formulaSelectTarget.value === '1') {
      this.kpiInput1Target.style.display = 'block'
      this.kpiInput2Target.style.display = 'none'
    } else {
      this.kpiInput1Target.style.display = 'block'
      this.kpiInput2Target.style.display = 'block'
    }
  }

  handleChangeFormularSelect() {
    this.checkKPIInput()
  }

  checkKPILevelTemplate() {
    if (this.stypeSelectTarget.value === '1') {
      this.kpiLevelTemplate1Target.style.display = 'block'
      this.kpiLevelTemplate2Target.style.display = 'none'
    } else {
      this.kpiLevelTemplate1Target.style.display = 'none'
      this.kpiLevelTemplate2Target.style.display = 'block'
    }
  }

  handleChangeStypeSelect() {
    this.checkKPILevelTemplate()
  }

  handleChangeKPILevel(e) {
    this.formArr.forEach(item => {
      if (e.target.name === item.name) {
        item.value = e.target.value
      }
    })
  }
}
