import { Controller } from "stimulus";
import {
  api as apiHelper,
  error as errorHelper,
  report,
  utils,
} from "../helper";

export default class extends Controller {
  static values = { url: String };
  static targets = [
    "group",
    "specialGroup",
    "submit",
    "budgetYear",
    "startRound",
    "endRound",
    "yearType",
    "periodType",
    "datepickerContainer",
    "yearSelectContainer",
    "startYear",
    "endYear",
  ];

  connect() {
    this.hasBudgetYearTarget &&
      this.budgetYearTarget.addEventListener(
        "change",
        this.kpiGroupListLoading
      );

    if (this.hasGroupTarget && this.hasSpecialGroupTarget) {
      utils.disableInput(this.groupTarget, this.specialGroupTarget.value);
      utils.disableInput(this.specialGroupTarget, this.groupTarget.value);

      this.groupTarget.addEventListener("change", () => {
        utils.disableInput(this.specialGroupTarget, this.groupTarget.value);
      });

      this.specialGroupTarget.addEventListener("change", () => {
        utils.disableInput(this.groupTarget, this.specialGroupTarget.value);
      });
    }

    if (this.hasStartRoundTarget && this.hasEndRoundTarget) {
      this.startRoundTarget.addEventListener("change", () =>
        this.hardRoundChange()
      );
      this.endRoundTarget.addEventListener("change", () =>
        this.hardRoundChange()
      );
    }

    this.submitTarget.addEventListener("click", () => {
      if (document.querySelector('[data-name="value-block"]')) {
        document.querySelector('[data-name="value-block"]').innerHTML =
          '<div class="loader"></div>';
      }
    });

    document.addEventListener("ajax:error", (event) => {
      if (document.querySelector('[data-name="value-block"]')) {
        document.querySelector('[data-name="value-block"]').innerHTML = "";
        errorHelper.showErrorDialog(event.detail[2].status);
      }
    });

    report.togglePeriodType.call(this, this.data.get("period-type"));
  }

  kpiGroupListLoading() {
    if (document.getElementById("kpi_group_list")) {
      document.getElementById("kpi_group_list").innerHTML =
        '<div class="loader"></div>';
    }
  }

  hardRoundChange() {
    this.callKpiList();
    this.kpiGroupListLoading();
  }

  async callKpiList() {
    const kpiListUrl = new URL(this.data.get("get-kpi-url"));

    if (this.hasStartRoundTarget) {
      kpiListUrl.searchParams.append("start_round", this.startRoundTarget.value);
    }

    if (this.hasEndRoundTarget) {
      kpiListUrl.searchParams.append("end_round", this.endRoundTarget.value);
    }

    if (this.hasYearTypeTarget) {
      kpiListUrl.searchParams.append("year_type", this.yearTypeTarget.value);
    }

    if (this.hasPeriodTypeTarget) {
      kpiListUrl.searchParams.append("period_type", this.periodTypeTarget.value);
    }

    if (this.hasStartYearTarget) {
      kpiListUrl.searchParams.append("start_year", this.startYearTarget.value);
    }

    if (this.hasEndYearTarget) {
      kpiListUrl.searchParams.append("end_year", this.endYearTarget.value);
    } else {
      kpiListUrl.searchParams.append("end_year", this.startYearTarget.value)
    }

    const res = await fetch(kpiListUrl, {
      headers: apiHelper.apiHarder(),
    });

    res.text().then((resText) => eval(resText));
  }

  changeStartRound() {
    this.hardRoundChange();
  }

  changeEndRound() {
    this.hardRoundChange();
  }

  changeYearType() {
    this.hardRoundChange();
  }

  changePeriodType(e) {
    report.togglePeriodType.call(this, e.target.value);
    this.hardRoundChange();
  }

  changeStartYearSelect(e) {
    if (
      this.hasEndYearTarget &&
      Number(e.target.value) > Number(this.endYearTarget.value)
    ) {
      this.endYearTarget.value = null;
    } else {
      this.hardRoundChange();
    }
  }

  changeEndYearSelect(e) {
    if (
      this.hasStartYearTarget &&
      Number(e.target.value) < Number(this.startYearTarget.value)
    ) {
      this.startYearTarget.value = null;
    } else {
      this.hardRoundChange();
    }
  }
}
