import { Controller } from "stimulus";
import { number, api as apiHelper } from "../helper";

export default class extends Controller {
  static targets = [
    "kpiInput1",
    "kpiInput2",
    "result",
    "marks",
    "canvas",
    "kpiValue",
    "lowerLimit",
    "kpiLevel2",
    "kpiLevel3",
    "kpiLevel4",
    "kpiLevel5",
    "upperLimit",
    "kpiFormulaType",
    "kpiName",
    "kpiInputMarks",
  ];

  completed = false;
  connect() {
    this.screenedOut = this.data.get("screenedOut") === "true";
    document.querySelectorAll(".treeview").forEach((el) => {
      el.classList.add("treeview--active");
    });

    document.querySelectorAll(".treeview__item").forEach((el) => {
      el.classList.add("treeview__item--active");
    });

    if (this.hasKpiInput1Target) {
      this.submit(this.kpiInput1Target);
    }

    if (this.hasKpiInput2Target) {
      this.submit(this.kpiInput2Target);
    }

    this.handleChange();

    if (this.hasCanvasTarget) {
      this.renderKpiValueChart();
    }
  }

  resultRate(result) {
    if (this.data.get("stypeId") == 1) {
      if (
        isNaN(this.lower) &&
        parseFloat(result) < parseFloat(this.data.get("level2"))
      ) {
        return 1;
      } else {
        return 2;
      }
    } else {
      if (
        isNaN(this.upper) &&
        parseFloat(result) > parseFloat(this.data.get("level5"))
      ) {
        return 1;
      } else {
        return 2;
      }
    }
  }

  handleChange() {
    this.have_divider = this.hasKpiInput2Target;
    let dividend = (this.dividend = this.hasKpiInput1Target
      ? parseFloat(this.kpiInput1Target.value)
      : null);
    let divider = (this.divider = this.hasKpiInput2Target
      ? parseFloat(this.kpiInput2Target.value)
      : null);
    this.result = this.have_divider
      ? number.parseDecimalPoint(eval(this.data.get("script")))
      : this.dividend || 0;
    this.completed = this.have_divider
      ? !Number.isNaN(this.divider) && !Number.isNaN(this.dividend)
      : !Number.isNaN(this.dividend);
    this.lower = parseFloat(this.data.get("lower"));
    this.upper = parseFloat(this.data.get("upper"));
    this.result_rate = this.resultRate(this.result);
    this.validations();

    if (this.hasResultTarget) {
      this.resultTarget.textContent = this.completed
        ? isFinite(this.result)
          ? `${this.result} ${this.data.get("unitName")}`
          : ""
        : "N/A";
    }
  }

  inputValidation(e) {
    let input = e.target;
    let value = Number(input.value);

    switch (input.dataset.number) {
      case "int":
        if (!Number.isInteger(value)) {
          alert({
            title: I18n.t("form.kpi_can_only_be_integer"),
            type: "alert",
            style: "danger",
          });
          input.value = null;
        }
        break;
      case "int+":
        if (!Number.isInteger(value) || value < 0) {
          alert({
            title: I18n.t("form.kpi_can_only_be_numeral"),
            type: "alert",
            style: "danger",
          });
          input.value = null;
        }
        break;
      case "float":
        if (!Number.isFinite(value)) {
          alert({
            title: I18n.t("form.kpi_can_only_be_decimal"),
            type: "alert",
            style: "danger",
          });
          input.value = null;
        }
        break;
      case "float+":
        if (!Number.isFinite(value) || value < 0) {
          alert({
            title: I18n.t("form.kpi_can_only_be_positive_decimal"),
            type: "alert",
            style: "danger",
          });
          input.value = null;
        }
        break;
    }
  }

  validations() {
    var icons = [];
    this.kpiInputMarksTarget.childNodes.forEach((element) => {
      if (element.dataset && element.dataset.script) {
        icons.push(
          this.checkValidations(
            element,
            this.completed,
            parseFloat(this.result),
            parseInt(this.result_rate),
            this.dividend,
            this.divider,
            this.have_divider,
            this.lower,
            this.upper,
            this.data.get("markIdList")
          )
        );
      }
    });
    this.toggleKpiNameClass(icons);
  }

  checkValidations(
    mark,
    completed,
    result,
    result_rate,
    dividend,
    divider,
    have_divider,
    lower,
    upper,
    mark_id_list
  ) {
    // parameter ใช้ใน function ที่ส่งมาจาก backend
    if ((dividend || divider) && eval(mark.dataset.script)) {
      mark.style.display = "block";
      return mark.dataset.icon;
    }
    mark.style.display = "none";
    return null;
  }

  toggleKpiNameClass(icons) {
    this.kpiNameTarget.classList.remove("link--warning");
    this.kpiNameTarget.classList.remove("link--danger");
    this.kpiNameTarget.classList.remove("link--success");
    this.kpiNameTarget.classList.remove("link--secondary");
    // "link--secondary"
    if (this.screenedOut) {
      this.kpiNameTarget.classList.add("link--secondary");
    } else if (icons.includes("wrong")) {
      this.kpiNameTarget.classList.add("link--danger");
    } else if (icons.includes("warn")) {
      this.kpiNameTarget.classList.add("link--warning");
    } else if (icons.includes("unfill")) {
      this.kpiNameTarget.classList.add("link--warning");
    } else if (icons.includes("right")) {
      this.kpiNameTarget.classList.add("link--success");
    }
  }

  submit(input) {
    input.addEventListener("change", () => {
      const formData = new FormData();
      formData.append("kpi_sheet_input[value]", input.value);
      input.parentElement.classList.add("input--loading");
      input.disabled = true;
      fetch(`${this.data.get("url")}?id=${input.id}`, {
        method: "POST",
        headers: apiHelper.apiHarder(),
        body: formData,
      })
        .then((res) => res.text())
        .then((data) => {
          data && eval(data);
        });
    });
  }

  renderKpiValueChart() {
    const ctx = this.canvasTarget.getContext("2d");
    const canvasOffset = 50;
    const canvasMax = 250;
    const indicatorBarWidth = 50;
    const minWidth = 50;

    const value =
      this.result !== "" && number.parseDecimalPoint(Number(this.result)); // ค่า value ของ kpi
    const chartType =
      this.kpiFormulaTypeTarget.value !== ""
        ? Number(this.kpiFormulaTypeTarget.value)
        : null; // ประเภทของ kpi จากน้อยไปมาก หรือ มากไปน้อย
    const lowerLimit =
      this.lowerLimitTarget.value !== ""
        ? Number(this.lowerLimitTarget.value)
        : null; // ค่าของล่างสุด
    const kpiLevel2 =
      this.kpiLevel2Target.value !== ""
        ? Number(this.kpiLevel2Target.value)
        : null;
    const kpiLevel3 =
      this.kpiLevel3Target.value !== ""
        ? Number(this.kpiLevel3Target.value)
        : null;
    const kpiLevel4 =
      this.kpiLevel4Target.value !== ""
        ? Number(this.kpiLevel4Target.value)
        : null;
    const kpiLevel5 =
      this.kpiLevel5Target.value !== ""
        ? Number(this.kpiLevel5Target.value)
        : null;
    const upperLimit =
      this.upperLimitTarget.value !== ""
        ? Number(this.upperLimitTarget.value)
        : null; // ค่าขอบบนสุด

    const redColor = {
      base: "#FC8181",
      light: "#FED7D7",
      dark: "#C53030",
    };

    const orangeColor = {
      base: "#F6AD55",
      light: "#FEEBC8",
      dark: "#C05621",
    };

    const yellowColor = {
      base: "#F6E05E",
      light: "#FEFCBF",
      dark: "#B7791F",
    };

    const blueColor = {
      base: "#4B8DFC",
      light: "#ADCAFC",
      dark: "#0A3E96",
    };

    const greenColor = {
      base: "#68D391",
      light: "#C6F6D5",
      dark: "#2F855A",
    };

    const lessBetterIndicator = {
      level1: {
        position: 50,
        color: greenColor,
      },
      level2: {
        position: 100,
        color: blueColor,
      },
      level3: {
        position: 150,
        color: yellowColor,
      },
      level4: {
        position: 200,
        color: orangeColor,
      },
      level5: {
        position: 250,
        color: redColor,
      },
    };

    const moreBetterIndicator = {
      level1: {
        position: 50,
        color: redColor,
      },
      level2: {
        position: 100,
        color: orangeColor,
      },
      level3: {
        position: 150,
        color: yellowColor,
      },
      level4: {
        position: 200,
        color: blueColor,
      },
      level5: {
        position: 250,
        color: greenColor,
      },
    };

    let boxPosition = null;
    let boxWidth = null;
    let indicatorStyle = null; // object ที่เก็บค่า position และค่าสี ตาม kpi type
    let lightColor = null; // สีกล่องข้อความ
    let darkColor = null; // สีค่า value
    let valuePosition = null; // ค่าตำแหน่งแกน x ของ kpi value

    if (chartType === 1) {
      indicatorStyle = moreBetterIndicator;
    } else {
      indicatorStyle = lessBetterIndicator;
    }

    // set สีให้กล่องข้อความตาม value
    if (value < kpiLevel2) {
      lightColor = indicatorStyle.level1.color.light;
      darkColor = indicatorStyle.level1.color.dark;
      valuePosition = indicatorStyle.level1.position + 25;
    } else if (value < kpiLevel3) {
      lightColor = indicatorStyle.level2.color.light;
      darkColor = indicatorStyle.level2.color.dark;
      valuePosition = indicatorStyle.level2.position + 25;
    } else if (value < kpiLevel4) {
      lightColor = indicatorStyle.level3.color.light;
      darkColor = indicatorStyle.level3.color.dark;
      valuePosition = indicatorStyle.level3.position + 25;
    } else if (value < kpiLevel5) {
      lightColor = indicatorStyle.level4.color.light;
      darkColor = indicatorStyle.level4.color.dark;
      valuePosition = indicatorStyle.level4.position + 25;
    } else if (value >= kpiLevel5) {
      lightColor = indicatorStyle.level5.color.light;
      darkColor = indicatorStyle.level5.color.dark;
      valuePosition = indicatorStyle.level5.position + 25;
    }

    if (lowerLimit || upperLimit) {
      if (value < lowerLimit) {
        valuePosition = 37.5;
        ctx.fillStyle = indicatorStyle.level1.color.base;
        ctx.fillRect(
          indicatorStyle.level1.position - indicatorBarWidth / 2,
          50,
          indicatorBarWidth / 2,
          12
        );
      } else if (value > upperLimit) {
        valuePosition = 312.5;
        ctx.fillStyle = indicatorStyle.level5.color.base;
        ctx.fillRect(300, 50, indicatorBarWidth / 2, 12);
      }
    }

    // วาดกล่องของแต่ละสี
    ctx.fillStyle = indicatorStyle.level1.color.base;
    ctx.fillRect(indicatorStyle.level1.position, 50, indicatorBarWidth, 12);
    ctx.fillStyle = indicatorStyle.level2.color.base;
    ctx.fillRect(indicatorStyle.level2.position, 50, indicatorBarWidth, 12);
    ctx.fillStyle = indicatorStyle.level3.color.base;
    ctx.fillRect(indicatorStyle.level3.position, 50, indicatorBarWidth, 12);
    ctx.fillStyle = indicatorStyle.level4.color.base;
    ctx.fillRect(indicatorStyle.level4.position, 50, indicatorBarWidth, 12);
    ctx.fillStyle = indicatorStyle.level5.color.base;
    ctx.fillRect(indicatorStyle.level5.position, 50, indicatorBarWidth, 12);

    // set style font
    ctx.font = "16px Arial";
    ctx.fillStyle = "#777";
    ctx.textAlign = "center";

    // แสดงค่าขอบของแต่ละสี
    lowerLimit !== null &&
      ctx.fillText(lowerLimit, indicatorStyle.level1.position, 82);
    kpiLevel2 !== null &&
      ctx.fillText(kpiLevel2, indicatorStyle.level2.position, 82);
    kpiLevel3 !== null &&
      ctx.fillText(kpiLevel3, indicatorStyle.level3.position, 82);
    kpiLevel4 !== null &&
      ctx.fillText(kpiLevel4, indicatorStyle.level4.position, 82);
    kpiLevel5 !== null &&
      ctx.fillText(kpiLevel5, indicatorStyle.level5.position, 82);
    upperLimit !== null &&
      ctx.fillText(
        upperLimit,
        indicatorStyle.level5.position + canvasOffset,
        82
      );

    if (value && !isNaN(value)) {
      let txt = value;
      let txtWidth = ctx.measureText(txt).width;

      function roundRect(ctx, x, y, width, height, radius, fill, stroke) {
        if (typeof stroke === "undefined") {
          stroke = true;
        }
        if (typeof radius === "undefined") {
          radius = 5;
        }
        if (typeof radius === "number") {
          radius = {
            tl: radius,
            tr: radius,
            br: radius,
            bl: radius,
          };
        } else {
          var defaultRadius = {
            tl: 0,
            tr: 0,
            br: 0,
            bl: 0,
          };
          for (var side in defaultRadius) {
            radius[side] = radius[side] || defaultRadius[side];
          }
        }
        ctx.beginPath();
        ctx.moveTo(x + radius.tl, y);
        ctx.lineTo(x + width - radius.tr, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
        ctx.lineTo(x + width, y + height - radius.br);
        ctx.quadraticCurveTo(
          x + width,
          y + height,
          x + width - radius.br,
          y + height
        );
        ctx.lineTo(x + radius.bl, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
        ctx.lineTo(x, y + radius.tl);
        ctx.quadraticCurveTo(x, y, x + radius.tl, y);
        ctx.closePath();
        if (fill) {
          ctx.fill();
        }
        if (stroke) {
          ctx.stroke();
        }
      }

      if (txtWidth + 10 < minWidth) {
        boxWidth = minWidth;
      } else {
        boxWidth = txtWidth * 2;
      }

      ctx.strokeStyle = lightColor;
      ctx.fillStyle = lightColor;
      boxPosition = valuePosition - boxWidth / 2;
      roundRect(ctx, boxPosition, 15, boxWidth, 30, 10, true);

      // วาดสามเหลี่ยมกล่องข้อความ
      ctx.beginPath();
      ctx.moveTo(valuePosition, 55);
      ctx.lineTo(valuePosition + 8, 45);
      ctx.lineTo(valuePosition - 8, 45);
      ctx.closePath();
      ctx.fillStyle = lightColor;
      ctx.fill();

      // แสดงค่า value ในกล่องข้อความ
      ctx.fillStyle = darkColor;
      ctx.font = "20px Arial";
      ctx.fillText(value, valuePosition, 38);
    }
  }
}
