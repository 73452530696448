import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["container"]

  connect() {
    let that = this
    document.addEventListener("click", function(event) {
      if (that.containerTarget.classList.contains("popover--active")) {
        if (!that.containerTarget.contains(event.target)) {
          that.containerTarget.classList.remove("popover--active")
        }
      }
    })
  }

  handleOpenPopover() {
    let eventY = event.y
    let eventX = event.x
    let currentY = event.view.innerHeight
    let currentX = event.view.innerWidth

    if (!((currentY / 2) > eventY)) {
      this.containerTarget.classList.add("popover--active")
      this.containerTarget.classList.add("popover--top")
    } else {
      this.containerTarget.classList.add("popover--active")
      this.containerTarget.classList.remove("popover--top")
    }

    if (eventX < (currentX / 2)) {
      this.containerTarget.classList.add("popover--active")
      this.containerTarget.classList.add("popover--right")
    } else {
      this.containerTarget.classList.add("popover--active")
      this.containerTarget.classList.remove("popover--right")
    }
  }

  handleClosePopover() {
    this.containerTarget.classList.remove("popover--active")
    this.containerTarget.classList.remove("popover--top")
  }
}
