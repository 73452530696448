import { Controller } from "stimulus";
import { api as apiHelper } from "../helper";
// import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ["chatList", "input", "submit"];

  connect() {
    let loadMore = "<div id='load_more' class='badge'>load more message</div>";
    this.chatListTarget.scrollTop = this.chatListTarget.scrollHeight;

    // this.subscription = consumer.subscriptions.create(
    //   {
    //     channel: 'ChatChannel',
    //     room: `${this.data.get('kpiId')}_${this.data.get('kpiSheetId')}`
    //   },
    //   {
    //     received: this._received.bind(this)
    //   }
    // )

    this.inputTarget.addEventListener("keypress", (e) => {
      if (e.key === "Enter") {
        this.handleSubmit(e);
      }
    });

    this.chatListTarget.addEventListener("scroll", () => {
      if (this.chatListTarget.scrollTop === 0) {
        this.chatListTarget.insertAdjacentHTML("afterbegin", loadMore);

        this.getMoreMessage().then((data) => {
          if (data) {
            this.chatListTarget.scrollTop =
              this.chatListTarget.querySelector("#load_more").scrollHeight; // scroll กลับไปที่ element เก่าบนสุด
            this.chatListTarget.insertAdjacentHTML("afterbegin", data);
            let currentPage = parseInt(this.data.get("page"));
            let nextPage = (currentPage += 1);
            this.data.set("page", nextPage); // set ค่า page ปัจจุบัน
          }
          this.chatListTarget.removeChild(
            this.chatListTarget.querySelector("#load_more")
          );
        });
      }
    });
  }

  disconnect() {
    // consumer.subscriptions.remove(this.subscription)
  }

  // _received(data) {
  //   let html = `
  //     <div class="chat__item ${data.current_user.id === data.from_user.id ? 'chat__item--self' : ''}">
  //       <div class="chat__item__header">
  //         <div class="chat__item__image"></div>
  //         <div class="chat__item__title">${data.from_user.first_name_th} ${data.from_user.last_name_th}</div>
  //       </div>
  //       <div class="chat__item__content">
  //         ${data.message.message}
  //       </div>
  //       <div class="chat__item__meta">
  //         ${data.message.created_at}
  //       </div>
  //     </div>
  //   `
  //   console.log("data ---", data)
  //   let chatIcon = document.getElementById(`chatIcon${data.message.kpi_id}_${data.message.kpi_sheet_id}`)
  //   chatIcon.classList.contains("link--primary") || chatIcon.classList.add("link--primary")
  //   this.chatListTarget.insertAdjacentHTML('beforeend', html)
  //   this.chatListTarget.scrollTop = this.chatListTarget.scrollHeight
  // }

  handleSubmit(e) {
    e.preventDefault();

    const formData = new FormData();
    formData.append("kpi_message[kpi_id]", this.data.get("kpiId"));
    formData.append("kpi_message[user_id]", this.data.get("user"));
    formData.append("kpi_message[kpi_sheet_id]", this.data.get("kpiSheetId"));
    formData.append("kpi_message[message]", this.inputTarget.value);

    if (this.inputTarget.value) {
      this.submit(this.data.get("submitUrl"), formData);
    }

    this.inputTarget.value = "";
  }

  async getMoreMessage() {
    const res = await fetch(
      `${this.data.get("url")}?kpi_id=${this.data.get(
        "kpiId"
      )}&kpi_sheet_id=${this.data.get("kpiSheetId")}&page=${this.data.get(
        "page"
      )}`
    );
    let data = await res.text();
    return data;
  }

  async submit(url, data) {
    await fetch(url, {
      method: "POST",
      headers: apiHelper.apiHarder(),
      body: data,
    });
  }
}
