import { Controller } from "stimulus"

export default class extends Controller {
  // static targets = [ 'infoPanel' ]
  connect() {
    this.url = this.data.get("url")
  }

  change(event) {
    let id = event.target.value
    console.log(this.url)

    let goTo = `${this.url}?budget_year=${id}`
    // if (location.search != "")
    //   goTo = goTo.concat(`${location.search}`)
  
    Turbo.visit(goTo)
  }
}
