const Rails = require("@rails/ujs")
import { Alert } from "./alert_ui"

const confirmed = (element, result) => {
  if (result.value) {
    element.removeAttribute('data-confirm')
    element.click()
  }
}

const showConfirmationDialog = (element) => {
  const title = element.getAttribute('data-confirm')
  const content = element.getAttribute('data-alert-content')
  const type = element.getAttribute('data-alert-type')
  const style = element.getAttribute('data-alert-style')

  alert({
    title: title,
    content: content,
    type: type,
    style: style
  }).then(result => {
    confirmed(element, result)
  }).catch(err => {})
}

const allowAction = (element) => {
  if (element.getAttribute('data-confirm') === null) {
    return true
  }

  showConfirmationDialog(element)
  return false
}

function handleConfirm(element) {
  if (!allowAction(this)) {
    Rails.stopEverything(element)
  }
}

Rails.delegate(document, '[data-confirm]', 'click', handleConfirm)

window.alert = Alert
