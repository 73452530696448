import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ["note", "request", "noteInput"]

  connect() {
    this.hideNoteBlock()
  }

  handleChange(e) {
    if (this.requestTarget.value === '6') {
      this.showNoteBlock()
    } else {
      this.hideNoteBlock()
    }
  }

  showNoteBlock() {
    this.noteTarget.style.display = ''

    if (this.hasNoteInputTarget) {
      this.noteInputTarget.required = true
    }
  }

  hideNoteBlock() {
    this.noteTarget.style.display = 'none'

    if (this.hasNoteInputTarget) {
      this.noteInputTarget.required = false
    }
  }
}
