import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]

  connect() {
    this.groupId = this.data.get('groupId')
    console.log(this.groupId)
  }
}
