import { Controller } from "stimulus"

export default class extends Controller {
  // static targets = [ 'infoPanel' ]
  connect() {
    // console.log('connect')
    this.url = this.data.get("url")
    // this.asQuery = this.toBoolean(this.data.get("as-query"))
    // console.log(this.asQuery)
  }

  change(event) {
    let id = event.target.value

    let goTo = `${this.url}?budget_year_id=${id}`
    // if (location.search != "")
    //   goTo = goTo.concat(`${location.search}`)

    Turbo.visit(goTo)
  }

  // toBoolean(string) {
  //   switch (string.toLowerCase()) {
  //     case "true": case "yes": case "1": return true
  //     case "false": case "no": case "0": case null: return false
  //     default: return Boolean(string)
  //   }
  // }
}
