import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "step",
    "stepForm",
    "cancelButton",
    "backButton",
    "nextButton",
    "submitButton",
    "form",
    "changwat",
    "amphoe",
    "tambon",
    "name",
    "hospitalId",
    "nameTH",
    "code",
    "addressTH",
    "address",
    "phoneNumber",
    "fax",
    "qicPhoneNumber",
    "quicEmail",
    "unit",
    "group",
    "groupUnit",
    "directorPreName",
    "directorFirstName",
    "directorLastName",
    "coop1PreName",
    "coop1FirstName",
    "coop1LastName",
    "coop1PhoneNumber",
    "coop1Email",
    "coop2PreName",
    "coop2FirstName",
    "coop2LastName",
    "coop2PhoneNumber",
    "coop2Email",
    "users0FirstNameTH",
    "users0LastNameTH",
    "users0FirstName",
    "users0LastName",
    "users0PhoneNumber",
    "users0Email",
    "users1FirstNameTH",
    "users1LastNameTH",
    "users1FirstName",
    "users1LastName",
    "users1PhoneNumber",
    "users1Email",
    "users2FirstNameTH",
    "users2LastNameTH",
    "users2FirstName",
    "users2LastName",
    "users2PhoneNumber",
    "users2Email",
    "sectionLeft",
    "stepContainer",
    "actionContainer",
    "registeredSize",
    "actualSize",
    "haCertifiedDate",
    "haExpireDate",
    "certCertifiedDate",
    "certExpireDate",
    "nameTHSum",
    "checkbox",
    "codeSum",
    "nameSum",
    "changwatSum",
    "amphoeSum",
    "tambonSum",
    "addressTHSum",
    "addressSum",
    "phoneNumberSum",
    "faxSum",
    "qicPhoneNumberSum",
    "quicEmailSum",
    "unitSum",
    "groupSum",
    "groupUnitSum",
    "directorPreNameSum",
    "directorFirstNameSum",
    "directorLastNameSum",
    "coop1PreNameSum",
    "coop1FisrtNameSum",
    "coop1LastNameSum",
    "coop1PhoneNumberSum",
    "coop1EmailSum",
    "coop2PreNameSum",
    "coop2FirstNameSum",
    "coop2LastNameSum",
    "coop2PhoneNumberSum",
    "coop2EmailSum",
    "users0FisrtNameTHSum",
    "users0LastNameTHSum",
    "users0FirstNameSum",
    "users0LastNameSum",
    "users0PhoneNumberSum",
    "users0EmailSum",
    "users1FirstNameTHSum",
    "users1LastNameTHSum",
    "users1PhoneNumberSum",
    "users1EmailSum",
    "users2FirstNameTHSum",
    "users2LastNameTHSum",
    "users2FirstNameSum",
    "users2LastNameSum",
    "users2PhoneNumberSum",
    "users2EmailSum",
    "coop1FirstNameSum",
    "users0FirstNameTHSum",
    "users1FirstNameSum",
    "users1LastNameSum",
    "area",
    "areaSum",
    "registeredSizeSum",
    "actualSizeSum",
    "haStatus",
    "haStatusSum",
    "haCertifiedDateSum",
    "haExpireDateSum",
    "certificateOther",
    "certificateOtherSum",
    "certificate",
    "certCertifiedDateSum",
    "certExpireDateSum",
    "invoiceName",
    "invoiceNameSum",
    "paymentNote",
    "paymentNoteSum",
    "note",
    "noteSum",
    "kpiListError",
    "hospitalPrograms",
    "hospitalProgramsSum",
    "taxId",
    "taxIdSum",
    "paymentPostalCode",
    "paymentPostalCodeSum",
    "eTaxMail",
    "eTaxMailSum",
  ];

  initialize() {
    this.invalid = [];

    this.hasBackButtonTarget &&
      this.backButtonTarget.addEventListener("click", (e) => {
        e.preventDefault();
      });

    this.hasNextButtonTarget &&
      this.nextButtonTarget.addEventListener("click", (e) => {
        e.preventDefault();
      });

    this.formTarget.addEventListener("keypress", (e) => {
      if (e.which === 13 && e.target.type !== "textarea") {
        e.preventDefault();
      }
    });

    this.taxIdTarget.addEventListener("paste", this.pasteTest);
    this.paymentPostalCodeTarget.addEventListener("paste", this.pasteTest);

    this.showForm(0);
  }

  async next() {
    await this.validateWizardForm();

    if (this.invalid.length === 0) {
      this.showForm(this.index + 1);
      document.documentElement.scrollTop = 0;
    }

    this.toggleImage();
    this.toggleStep3Class();
  }

  previous() {
    if (this.index > -1) {
      this.invalid = [];
      this.showForm(this.index - 1);
      document.documentElement.scrollTop = 0;
      this.toggleImage();
      this.toggleStep3Class();
    }
  }

  changeStep(index) {
    this.stepTargets.forEach((el, i) => {
      el.classList.toggle("step__item--active", index == i);
      el.classList.toggle("step__item--completed", index > i);
    });
  }

  changeForm(index) {
    this.stepFormTargets.forEach((el, i) => {
      el.classList.toggle("step-form--active", index == i);
    });
  }

  showButton(index) {
    this.submitButtonTarget.style.display = "none";
    this.cancelButtonTarget.style.display = "none";

    if (index == 0) {
      this.backButtonTarget.style.display = "none";
      this.cancelButtonTarget.style.display = "inline-block";
    } else if (index == 4) {
      this.nextButtonTarget.style.display = "none";
      this.submitButtonTarget.style.display = "inline-block";
    } else {
      this.nextButtonTarget.style.display = "inline-block";
      this.backButtonTarget.style.display = "inline-block";
    }
  }

  showForm(index) {
    this.index = index;
    this.changeStep(index);
    this.changeForm(index);
    this.hasSubmitButtonTarget &&
      this.hasCancelButtonTarget &&
      this.showButton(index);
  }

  addInvalidElement(element, elementIndex) {
    if (!this.invalid.find((item) => item.id === element.id)) {
      this.invalid.splice(elementIndex, 0, {
        id: element.id,
        element: element,
      });
    }
  }

  updateInvalidElement(element) {
    if (this.invalid.find((item) => item.id === element.id)) {
      let findIndex = this.invalid.findIndex((item) => item.id === element.id);
      this.invalid[findIndex] = {
        id: element.id,
        element: element,
      };
    }
  }

  removeInvalidElement(element) {
    if (this.invalid.find((item) => item.id === element.id)) {
      this.invalid = this.invalid.filter((item) => item.id !== element.id);
    }
  }

  validateField(element) {
    let formArr = Array.prototype.slice.call(this.formTarget.elements);
    let elementIndex = formArr
      .filter(
        (item) =>
          item.type !== "hidden" &&
          item.required &&
          !element.disabled &&
          !item.validity.valid
      )
      .indexOf(element);

    if (!element.disabled && !element.validity.valid) {
      element.parentElement.classList.add("input--error");

      if (!element.parentElement.querySelector(".input__message")) {
        element.insertAdjacentHTML(
          "afterend",
          `<div class="input__message">${element.validationMessage}</div>`
        );
      } else {
        element.parentElement.querySelector(".input__message").innerHTML =
          element.validationMessage;
      }

      if (element.id === "registered_hospital_name_th") {
        let findIndex = this.invalid.findIndex(
          (item) => item.id === "registered_hospital_hospital_id"
        );
        if (findIndex !== -1) {
          this.invalid[findIndex] = {
            id: element.id,
            element: element,
          };
        }
      }

      if (element.id === "registered_hospital_hospital_id") {
        let findIndex = this.invalid.findIndex(
          (item) => item.id === "registered_hospital_name_th"
        );
        if (findIndex !== -1) {
          this.invalid[findIndex] = {
            id: element.id,
            element: element,
          };
        }
      }

      // Add new error element to invalid
      this.addInvalidElement(element, elementIndex);

      // Update error element
      this.updateInvalidElement(element);
    } else {
      element.parentElement.classList.remove("input--error");

      if (element.parentElement.querySelector(".input__message")) {
        element.parentElement.removeChild(
          element.parentElement.querySelector(".input__message")
        );
      }

      this.removeInvalidElement(element);
    }

    this.specialValidation(element);

    if (element.classList.contains("flatpickr-input")) {
      this.validateDatePicker(element);
    }
  }

  validateDatePicker(element) {
    let formArr = Array.prototype.slice.call(this.formTarget.elements);
    let elementIndex = formArr
      .filter(
        (item) =>
          item.type !== "hidden" &&
          item.required &&
          !element.disabled &&
          !item.validity.valid
      )
      .indexOf(element);

    if (element.required && !element.value) {
      element.parentElement.classList.add("input--error");

      if (!element.parentElement.querySelector(".input__message")) {
        element.parentElement.insertAdjacentHTML(
          "beforeend",
          `<div class="input__message">${I18n.t("form.select_date")}</div>`
        );
      } else {
        element.parentElement.querySelector(".input__message").innerHTML =
          I18n.t("form.select_date");
      }

      // Add new error element to invalid
      this.addInvalidElement(element, elementIndex);

      // Update error element
      this.updateInvalidElement(element);
    } else {
    }
  }

  async fetchValidate(email, userId) {
    let url = this.data.get("validateEmailUrl");
    url += `?code=${this.codeTarget.value}`;

    if (email) {
      url += `&email=${email}`;
    }

    if (userId) {
      url += `&userId=${userId}`;
    }

    const res = await fetch(url);
    const result = await res.json();
    return result;
  }

  async validateUserEmail(element) {
    let formArr = Array.prototype.slice.call(this.formTarget.elements);
    let elementIndex = formArr
      .filter(
        (item) =>
          item.type !== "hidden" &&
          item.required &&
          !element.disabled &&
          !item.validity.valid
      )
      .indexOf(element);

    if (element.value) {
      const checkEmailResult = await this.fetchValidate(
        element.value,
        element.dataset.userId || element.dataset.userid
      );

      if (checkEmailResult) {
        element.parentElement.classList.add("input--error");
        element.insertAdjacentHTML(
          "afterend",
          `<div class="input__message">${I18n.t(
            "form.email_already_registered"
          )}</div>`
        );

        if (!this.invalid.find((item) => item.id === element.id)) {
          this.invalid.splice(elementIndex, 0, {
            id: element.id,
            element: element,
          });
        }

        if (this.invalid.find((item) => item.id === element.id)) {
          let findIndex = this.invalid.findIndex(
            (item) => item.id === element.id
          );
          this.invalid[findIndex] = {
            id: element.id,
            element: element,
          };
        }
      } else {
        element.parentElement.classList.remove("input--error");

        if (element.nextElementSibling) {
          element.parentElement.removeChild(element.nextElementSibling);
        }

        if (this.invalid.find((item) => item.id === element.id)) {
          this.invalid = this.invalid.filter((item) => item.id !== element.id);
        }
      }
    }
  }

  specialValidation(element) {
    if (element.name === "registered_hospital[hospital][users][][email]") {
      var emailElements = document.getElementsByName(element.name);
      emailElements.forEach((e, index) => {
        var errorMessage = "";
        if (e.value) {
          emailElements.forEach((element, i) => {
            if (i !== index && element.value && element.value === e.value) {
              errorMessage = I18n.t("form.email_must_unique");
            }
          });
        }
        e.setCustomValidity(errorMessage);
      });
    } else if (
      element.name ===
        "registered_hospital[hospital][users][][first_name_th]" ||
      element.name === "registered_hospital[hospital][users][][last_name_th]"
    ) {
      for (let i = 1; i <= 3; i++) {
        // fix 3 รอบ
        var errorMessage = "";
        var firstName = document.getElementById(
          `registered_hospital_first_name_th_${i}`
        );
        var lastName = document.getElementById(
          `registered_hospital_last_name_th_${i}`
        );
        if (firstName.value && lastName.value) {
          for (let index = 1; index <= 3; index++) {
            // fix 3 รอบ
            if (
              i !== index &&
              firstName.value ===
                document.getElementById(
                  `registered_hospital_first_name_th_${index}`
                ).value &&
              lastName.value ===
                document.getElementById(
                  `registered_hospital_last_name_th_${index}`
                ).value
            ) {
              errorMessage = I18n.t("form.name_must_unique");
            }
          }
        }
        firstName.setCustomValidity(errorMessage);
        lastName.setCustomValidity(errorMessage);
      }
    }
  }

  focusInvalidElement() {
    if (this.invalid.length !== 0) {
      if (this.invalid[0].element.classList.contains("flatpickr-input")) {
        this.invalid[0].element.nextElementSibling.focus();
      } else {
        this.invalid[0].element.focus();
      }
    }
  }

  validateStep1() {
    this.validateField(this.changwatTarget);
    this.validateField(this.amphoeTarget);
    this.validateField(this.tambonTarget);

    if (this.hasNameTHTarget) {
      this.invalid = this.invalid.filter(
        (item) => item.id !== "registered_hospital_hospital_id"
      );
      this.validateField(this.nameTHTarget);
    }

    if (this.hasHospitalIdTarget) {
      this.invalid = this.invalid.filter(
        (item) => item.id !== "registered_hospital_name_th"
      );
      this.validateField(this.hospitalIdTarget);
    }

    this.validateField(this.nameTarget);
    this.validateField(this.codeTarget);
    this.validateField(this.addressTHTarget);
    this.validateField(this.addressTarget);
    this.validateField(this.phoneNumberTarget);
    this.validateField(this.faxTarget);
    this.validateField(this.qicPhoneNumberTarget);
    this.validateField(this.quicEmailTarget);
    this.validateField(this.groupTarget);
    this.validateField(this.unitTarget);
    this.validateField(this.groupUnitTarget);
    this.validateField(this.haCertifiedDateTarget);
    this.validateField(this.haExpireDateTarget);
    this.validateField(this.certCertifiedDateTarget);
    this.validateField(this.certExpireDateTarget);
    this.validateField(this.invoiceNameTarget);
    this.validateField(this.taxIdTarget);
    this.validateField(this.paymentPostalCodeTarget);
    this.validateField(this.eTaxMailTarget);
    this.validateField(this.paymentNoteTarget);
    this.focusInvalidElement();
    this.setStep1Sum();
  }

  validateStep2() {
    this.validateField(this.directorPreNameTarget);
    this.validateField(this.directorFirstNameTarget);
    this.validateField(this.directorLastNameTarget);
    this.validateField(this.coop1PreNameTarget);
    this.validateField(this.coop1FirstNameTarget);
    this.validateField(this.coop1LastNameTarget);
    this.validateField(this.coop1PhoneNumberTarget);
    this.validateField(this.coop1EmailTarget);
    this.coop2PreNameTarget.value &&
      this.validateField(this.coop2PreNameTarget);
    this.coop2FirstNameTarget.value &&
      this.validateField(this.coop2FirstNameTarget);
    this.coop2LastNameTarget.value &&
      this.validateField(this.coop2LastNameTarget);
    this.coop2PhoneNumberTarget.value &&
      this.validateField(this.coop2PhoneNumberTarget);
    this.coop2EmailTarget.value && this.validateField(this.coop2EmailTarget);
    this.focusInvalidElement();
    this.setStep2Sum();
  }

  async validateStep3() {
    this.validateField(this.users0FirstNameTHTarget);
    this.validateField(this.users0LastNameTHTarget);
    this.validateField(this.users0PhoneNumberTarget);
    this.validateField(this.users0EmailTarget);
    this.validateField(this.users1FirstNameTHTarget);
    this.validateField(this.users1LastNameTHTarget);
    this.validateField(this.users1PhoneNumberTarget);
    this.validateField(this.users1EmailTarget);
    this.validateField(this.users2FirstNameTHTarget);
    this.validateField(this.users2LastNameTHTarget);
    this.validateField(this.users2PhoneNumberTarget);
    this.validateField(this.users2EmailTarget);

    if (
      this.users0EmailTarget.validity.valid &&
      this.users1EmailTarget.validity.valid &&
      this.users2EmailTarget.validity.valid
    ) {
      await this.validateUserEmail(this.users0EmailTarget);
      await this.validateUserEmail(this.users1EmailTarget);
      await this.validateUserEmail(this.users2EmailTarget);
    }

    this.focusInvalidElement();
    this.setStep3Sum();
  }

  validateStep4() {
    // if (!this.checkboxTargets.some((item) => item.checked)) {
    //   this.addInvalidElement({ id: "kpi_list_selector" }, 0);

    //   if (!this.kpiListErrorTarget.hasChildNodes()) {
    //     this.kpiListErrorTarget.insertAdjacentHTML(
    //       "beforeend",
    //       `<div class="text text--danger">${I18n.t(
    //         "form.select_atleast_one_kpi"
    //       )}</div>`
    //     );
    //   }
    // } else {
    //   this.removeInvalidElement({ id: "kpi_list_selector" });
    //   this.kpiListErrorTarget.innerHTML = null;
    // }

    this.removeInvalidElement({ id: "kpi_list_selector" });
    this.kpiListErrorTarget.innerHTML = null;

    this.setStep4Sum();
  }

  async validateWizardForm() {
    switch (this.index) {
      case 0:
        this.validateStep1();
        break;
      case 1:
        this.validateStep2();
        break;
      case 2:
        await this.validateStep3();
        break;
      case 3:
        this.validateStep4();
        break;
    }
  }

  pasteTest(event) {
    window.setTimeout(() => {
      var characters = event.target.value;
      window.setTimeout(() => {
        if (!/^\d+$/.test(characters)) {
          event.target.value = "";
        }
      });
    });
  }

  validateWizardFormField(e) {
    this.validateField(e.target);
  }

  validateNumber(e) {
    var characters = String.fromCharCode(e.which);
    if (!/[0-9]/.test(characters)) {
      e.preventDefault();
    }
  }

  toggleImage() {
    if (this.hasSectionLeftTarget) {
      if (this.index === 3 || this.index === 4) {
        this.sectionLeftTarget.classList.add("section--hide");
      } else {
        this.sectionLeftTarget.classList.remove("section--hide");
      }
    }
  }

  toggleStep3Class() {
    if (this.hasStepContainerTarget && this.hasActionContainerTarget) {
      if (this.index === 3 || this.index === 4) {
        this.stepContainerTarget
          .querySelector(".form__groups__header")
          .classList.add("form__groups__header--hide");
        this.actionContainerTarget
          .querySelector(".form__groups__header")
          .classList.add("form__groups__header--hide");
        this.stepContainerTarget
          .querySelector(".form__groups__content")
          .classList.add("form__groups__content--full-width");
        this.actionContainerTarget
          .querySelector(".form__groups__content")
          .classList.add("form__groups__content--full-width");
        this.stepContainerTarget
          .querySelector(".form__groups__content")
          .classList.add("form__groups__content--center");
        this.actionContainerTarget
          .querySelector(".form__groups__content")
          .classList.add("form__groups__content--center");
      } else {
        this.stepContainerTarget
          .querySelector(".form__groups__header")
          .classList.remove("form__groups__header--hide");
        this.actionContainerTarget
          .querySelector(".form__groups__header")
          .classList.remove("form__groups__header--hide");
        this.stepContainerTarget
          .querySelector(".form__groups__content")
          .classList.remove("form__groups__content--full-width");
        this.actionContainerTarget
          .querySelector(".form__groups__content")
          .classList.remove("form__groups__content--full-width");
        this.stepContainerTarget
          .querySelector(".form__groups__content")
          .classList.remove("form__groups__content--center");
        this.actionContainerTarget
          .querySelector(".form__groups__content")
          .classList.remove("form__groups__content--center");
      }
    }
  }

  setStep1Sum() {
    this.nameTHSumTarget.textContent = this.nameTHTarget.value;
    this.codeSumTarget.textContent = this.codeTarget.value;
    this.nameSumTarget.textContent = this.nameTarget.value;
    this.changwatSumTarget.textContent =
      this.changwatTarget.options[this.changwatTarget.selectedIndex].text;
    this.amphoeSumTarget.textContent =
      this.amphoeTarget.options[this.amphoeTarget.selectedIndex].text;
    this.tambonSumTarget.textContent =
      this.tambonTarget.options[this.tambonTarget.selectedIndex].text;
    this.addressTHSumTarget.textContent = this.addressTHTarget.value;
    this.addressSumTarget.textContent = this.addressTarget.value;
    this.phoneNumberSumTarget.textContent = this.phoneNumberTarget.value;
    this.faxSumTarget.textContent = this.faxTarget.value;
    this.qicPhoneNumberSumTarget.textContent = this.qicPhoneNumberTarget.value;
    this.quicEmailSumTarget.textContent = this.quicEmailTarget.value;
    this.groupSumTarget.textContent =
      this.groupTarget.options[this.groupTarget.selectedIndex].text;
    this.groupUnitSumTarget.textContent =
      this.groupUnitTarget.options[this.groupUnitTarget.selectedIndex].text;
    this.unitSumTarget.textContent =
      this.unitTarget.options[this.unitTarget.selectedIndex].text;
    this.areaSumTarget.textContent =
      this.areaTarget.options[this.areaTarget.selectedIndex].text;
    this.registeredSizeSumTarget.textContent = this.registeredSizeTarget.value;
    this.actualSizeSumTarget.textContent = this.actualSizeTarget.value;

    if (
      this.haStatusTarget.options[this.haStatusTarget.selectedIndex].text !==
      I18n.t("form.not_yet_certification_process")
    ) {
      this.haStatusSumTarget.textContent =
        this.haStatusTarget.options[this.haStatusTarget.selectedIndex].text;
      this.haCertifiedDateSumTarget.textContent =
        this.haCertifiedDateTarget.nextSibling.value;
      this.haExpireDateSumTarget.textContent =
        this.haExpireDateTarget.nextSibling.value;
    } else {
      this.haStatusSumTarget.textContent =
        this.haStatusTarget.options[this.haStatusTarget.selectedIndex].text;
      this.haCertifiedDateSumTarget.textContent = "-";
      this.haExpireDateSumTarget.textContent = "-";
    }

    if (this.certificateOtherTarget.checked) {
      this.certificateOtherSumTarget.textContent = this.certificateTarget.value;
      this.certCertifiedDateSumTarget.textContent =
        this.certCertifiedDateTarget.nextSibling.value;
      this.certExpireDateSumTarget.textContent =
        this.certExpireDateTarget.nextSibling.value;
    } else {
      this.certificateOtherSumTarget.textContent = "-";
      this.certCertifiedDateSumTarget.textContent = "-";
      this.certExpireDateSumTarget.textContent = "-";
    }
    this.invoiceNameSumTarget.textContent = this.invoiceNameTarget.value
      ? this.invoiceNameTarget.value
      : "-";

    if (this.hasHospitalProgramsTarget) {
      const allCheckBoxPrograms = document.getElementsByName(
        this.hospitalProgramsTarget.name
      );

      if (allCheckBoxPrograms) {
        const checkedBoxProgramValues = [];
        var allPrograms = [];
        allCheckBoxPrograms.forEach((d) => {
          if (d.checked) {
            checkedBoxProgramValues.push(d.value);
            allPrograms = JSON.parse(d.dataset.all || "[]");
          }
        });
        this.hospitalProgramsSumTarget.textContent =
          checkedBoxProgramValues.length > 0
            ? (allPrograms || [])
                .filter((d) => checkedBoxProgramValues.includes(d.id + ""))
                .map((p) => p.name)
                .join(", ")
            : "-";
      }
    }
    this.taxIdSumTarget.textContent = this.taxIdTarget.value
      ? this.taxIdTarget.value
      : "-";
    this.paymentPostalCodeSumTarget.textContent = this.paymentPostalCodeTarget
      .value
      ? this.paymentPostalCodeTarget.value
      : "-";
    this.eTaxMailSumTarget.textContent = this.eTaxMailTarget.value
      ? this.eTaxMailTarget.value
      : "-";
    this.paymentNoteSumTarget.textContent = this.paymentNoteTarget.value
      ? this.paymentNoteTarget.value
      : "-";
  }

  setStep2Sum() {
    this.directorPreNameSumTarget.textContent = this.directorPreNameTarget.value
      ? this.directorPreNameTarget.value
      : "-";
    this.directorFirstNameSumTarget.textContent = this.directorFirstNameTarget
      .value
      ? this.directorFirstNameTarget.value
      : "-";
    this.directorLastNameSumTarget.textContent = this.directorLastNameTarget
      .value
      ? this.directorLastNameTarget.value
      : "-";
    this.coop1PreNameSumTarget.textContent = this.coop1PreNameTarget.value;
    this.coop1FirstNameSumTarget.textContent = this.coop1FirstNameTarget.value;
    this.coop1LastNameSumTarget.textContent = this.coop1LastNameTarget.value;
    this.coop1PhoneNumberSumTarget.textContent =
      this.coop1PhoneNumberTarget.value;
    this.coop1EmailSumTarget.textContent = this.coop1EmailTarget.value;
    this.coop2PreNameSumTarget.textContent = this.coop2PreNameTarget.value
      ? this.coop2PreNameTarget.value
      : "-";
    this.coop2FirstNameSumTarget.textContent = this.coop2FirstNameTarget.value
      ? this.coop2FirstNameTarget.value
      : "-";
    this.coop2LastNameSumTarget.textContent = this.coop2LastNameTarget.value
      ? this.coop2LastNameTarget.value
      : "-";
    this.coop2PhoneNumberSumTarget.textContent = this.coop2PhoneNumberTarget
      .value
      ? this.coop2PhoneNumberTarget.value
      : "-";
    this.coop2EmailSumTarget.textContent = this.coop2EmailTarget.value
      ? this.coop2EmailTarget.value
      : "-";
  }

  setStep3Sum() {
    this.users0FirstNameTHSumTarget.textContent =
      this.users0FirstNameTHTarget.value;
    this.users0LastNameTHSumTarget.textContent =
      this.users0LastNameTHTarget.value;
    this.users0PhoneNumberSumTarget.textContent =
      this.users0PhoneNumberTarget.value;
    this.users0EmailSumTarget.textContent = this.users0EmailTarget.value;
    this.users1FirstNameTHSumTarget.textContent =
      this.users1FirstNameTHTarget.value;
    this.users1LastNameTHSumTarget.textContent =
      this.users1LastNameTHTarget.value;
    this.users1PhoneNumberSumTarget.textContent =
      this.users1PhoneNumberTarget.value;
    this.users1EmailSumTarget.textContent = this.users1EmailTarget.value;
    this.users2FirstNameTHSumTarget.textContent =
      this.users2FirstNameTHTarget.value;
    this.users2LastNameTHSumTarget.textContent =
      this.users2LastNameTHTarget.value;
    this.users2PhoneNumberSumTarget.textContent =
      this.users2PhoneNumberTarget.value;
    this.users2EmailSumTarget.textContent = this.users2EmailTarget.value;
  }

  setStep4Sum() {
    this.noteSumTarget.textContent = this.noteTarget.value;

    const nodeArray = (selector, parent = document) =>
      Array.prototype.slice.call(parent.querySelectorAll(selector));

    this.checkboxTargets.forEach((item) => {
      if (item.checked) {
        document.getElementById(`${item.id}_sum`).checked = true;
      } else {
        document.getElementById(`${item.id}_sum`).checked = false;
      }

      this.parentCheck(nodeArray, document.getElementById(`${item.id}_sum`));
    });
  }

  parentCheck(nodeArray, check) {
    const parent = check
      .closest("ul")
      .parentNode.querySelector('input[type="checkbox"]');
    const siblings = nodeArray(
      'input[type="checkbox"]',
      parent.closest("li").querySelector("ul")
    );

    const checkStatus = siblings.map((check) => check.checked);
    const every = checkStatus.every(Boolean);
    const some = checkStatus.some(Boolean);

    parent.checked = every;
    parent.indeterminate = !every && every !== some;
    check = check != parent ? parent : 0;
  }
}
