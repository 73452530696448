import moment from "moment";

export const createFakeYearElement = (instance) => {
  const fakeYearElement = `<div class="flatpickr__fake-value">${
    handleYear(instance.currentYearElement.value)
  }</div>`;
  instance.currentYearElement.disabled = true;
  instance.currentYearElement.insertAdjacentHTML("afterend", fakeYearElement);
};

export const toBuddhistYear = (date, format) => {
  const initDate = moment(date).locale(localeConfig(document.documentElement.lang).locale);
  const christianYear = initDate.format("YYYY");
  const buddhishYear = handleYear(parseInt(christianYear)).toString();
  const result = initDate
    .format(
      format
        .replace("YYYY", buddhishYear)
        .replace("YY", buddhishYear.substring(2, 4))
    )
    .replace(christianYear, buddhishYear);
  return result;
};

export const toBuddhistMonthYear = (date) => {
  const monthYear = moment(date).locale(localeConfig(document.documentElement.lang).locale).format("MMMM YYYY").split(" ");
  const result = `${monthYear[0]} ${handleYear(monthYear[1])}`;
  return result;
};

export const handleYear = (year) =>{
  if(localeConfig(document.documentElement.lang).isTH){
    return Number(year) + 543;
  }
  return Number(year)
}


export const localeConfig = (locale) => {
  locale = locale || 'th';
  const isTH = locale === 'th';
  return {
    locale,
    isTH
  }
}

export const validateMonthRange = (
  currentInstance,
  isMonthRange,
  rangeMode,
  secondElement
) => {
  if (isMonthRange) {
    const secondInstance = document.querySelector(
      `#${secondElement}`
    )._flatpickr;

    if (rangeMode === "start") {
      if (currentInstance.selectedDates[0] > secondInstance.selectedDates[0]) {
        secondInstance.clear();
        secondInstance.open();
      }
    } else if (rangeMode === "end") {
      if (currentInstance.selectedDates[0] < secondInstance.selectedDates[0]) {
        secondInstance.clear();
        secondInstance.open();
      }
    }
  }
};
