// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// import mrujs from "mrujs";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "whatwg-fetch";
import { Turbo } from "@hotwired/turbo-rails";
import I18n from 'i18n-js'
window.I18n = I18n

window.Turbo = Turbo;
// mrujs.start();

if (!Element.prototype.matches)
  Element.prototype.matches = Element.prototype.msMatchesSelector;
if (!Element.prototype.closest)
  Element.prototype.closest = function (selector) {
    var el = this;
    while (el) {
      if (el.matches(selector)) {
        return el;
      }
      el = el.parentElement;
    }
  };

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

require("@rails/ujs").start();
import "@hotwired/turbo-rails"
require("@rails/activestorage").start();
require("channels");
require("trix");
require("@rails/actiontext");
require("./i18n/en")
require("./i18n/th")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers";
import AddonChart from "addon-chart-demo";
import "./alert";

AddonChart.usePlotly();

// embed
document.addEventListener("turbo:load", () => {
  let elements = document.querySelectorAll(
    "action-text-attachment[content-type^=embed]"
  );
  elements.forEach((element) => {
    let caption = element.getAttribute("caption");
    if (caption != null)
      element.insertAdjacentHTML("beforeend", `<caption>${caption}</caption>`);
  });

  document.addEventListener("click", function () {
    let linkInContents = document.querySelectorAll("div.trix-content a");
    linkInContents.forEach((link) => {
      link.setAttribute("target", "_blank");
    });
  });
});
