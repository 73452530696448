import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'editor', 'submit']

  validation(element) {
    element.validationMessage = I18n.t("form.enter_value")

    if (element.value) {
      this.valid = true

      element.parentElement.classList.remove('input--error')

      if (element.nextElementSibling) {
        element.parentElement.removeChild(element.nextElementSibling)
      }

      this.submitTarget.disabled = false
    } else {
      this.valid = false

      element.parentElement.classList.add('input--error')

      if (!element.nextElementSibling) {
        element.insertAdjacentHTML('afterend', `<div class="input__message">${element.validationMessage}</div>`)
      } else {
        element.nextElementSibling.innerHTML = element.validationMessage
      }
    }
  }

  formValidation(e) {
    e.preventDefault()

    this.validation(this.editorTarget)
  }

  formSubmit() {
    if (this.valid) {
      this.formTarget.submit()
    }
  }
}
