import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['dimmer', 'container']

  connect() {
    let that = this
    that.dimmerTarget.addEventListener("click", function (event) {
      if (that.dimmerTarget.classList.contains("dimmer--active")) {
        if (!that.containerTarget.contains(event.target)) {
          that.handleCloseDrawer()
        }
      }
    })
  }

  handleOpenDrawer() {
    setTimeout(() => {
      this.dimmerTarget.classList.add('dimmer--active')

      if (this.data.get('register') === 'true') {
        document.querySelector('.home-layout').classList.add('home-layout--disabled-scroll')
      } else {
        document.querySelector('.main-layout').classList.add('main-layout--disabled-scroll')
      }
    })
  }

  handleCloseDrawer() {
    this.dimmerTarget.classList.remove('dimmer--active')
    this.containerTarget.querySelector('.drawer__header').remove()
    this.containerTarget.querySelector('.drawer__content').remove()

    if (this.data.get('register') === 'true') {
      document.querySelector('.home-layout').classList.remove('home-layout--disabled-scroll')
    } else {
      if (this.containerTarget.querySelector('.drawer__footer')) {
        this.containerTarget.querySelector('.drawer__footer').remove()
      }

      document.querySelector('.main-layout').classList.remove('main-layout--disabled-scroll')
    }
  }
}
