import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "container", "closer" ]

  handleToggleSidebar() {
    this.containerTarget.classList.toggle("sidebar--active")
  }

  handleCloseSidebar() {
    this.containerTarget.classList.remove("sidebar--active")
  }
}
