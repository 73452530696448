import { Controller } from "stimulus";
import debounce from "debounce";

export default class extends Controller {
  static targets = ["result", "field"];

  connect() {
    this.fieldTarget.addEventListener(
      "keyup",
      debounce(() => {
        if (this.fieldTarget.value) {
          this.handleQuery(this.fieldTarget.value).then((result) => {
            if (result.status) {
              this.fieldTarget.setCustomValidity("");
              this.renderResult(result.html);
            } else {
              this.fieldTarget.setCustomValidity(I18n.t("form.enter_valid_value"));
              this.renderResult(result.html);
            }
          });
        } else {
          this.resultTarget.innerHTML = null;
          this.closeDropdown();

          if (this.data.get("hiddenField")) {
            document.getElementById(this.data.get("hiddenField")).value = null;
          }
        }
      }, 500)
    );

    this.fieldTarget.addEventListener("focus", () => {
      if (this.resultTarget.hasChildNodes()) {
        this.resultTarget.classList.add("autocomplete__result--active");
      }
    });

    document.addEventListener("click", (e) => {
      if (
        this.resultTarget.classList.contains("autocomplete__result--active")
      ) {
        if (!this.element.contains(e.target)) {
          this.closeDropdown();
        }
      }
    });

    document.addEventListener("keyup", (e) => {
      if (
        this.resultTarget.classList.contains("autocomplete__result--active")
      ) {
        if (!this.element.contains(e.target)) {
          this.closeDropdown();
        }
      }
    });
  }

  renderResult(result) {
    this.resultTarget.innerHTML = result;
    this.resultTarget.classList.add("autocomplete__result--active");
  }

  addValueHospitalProjectToField(e) {
    this.fieldTarget.value = e.target.dataset.name_th;
    var btnSubmit = document.getElementById("btn_submit");
    var hospitalProjectHospitalId = document.getElementById(
      "hospital_project_hospital_id"
    );

    if (e.target.dataset.h_id) {
      btnSubmit.disabled = false;
      hospitalProjectHospitalId.value = e.target.dataset.h_id;
    } else {
      btnSubmit.disabled = true;
      hospitalProjectHospitalId.value = null;
    }
    this.closeDropdown();
  }

  addValueSpecialToField(e) {
    this.fieldTarget.value = e.target.dataset.name_th;
    var btnSubmit = document.getElementById("btn_submit");
    var specialHospitalHospitalId = document.getElementById(
      "special_hospital_hospital_id"
    );

    if (e.target.dataset.h_id) {
      btnSubmit.disabled = false;
      specialHospitalHospitalId.value = e.target.dataset.h_id;
    } else {
      btnSubmit.disabled = true;
      specialHospitalHospitalId.value = null;
    }
    this.closeDropdown();
  }

  addValueToField(e) {
    this.fieldTarget.value = e.target.dataset.code;
    this.handleSetData(e.target.dataset.code).then((result) => {
      this.executeCode(result);
      this.closeDropdown();
    });
  }

  addValueToHiddenField(e) {
    this.fieldTarget.value = e.target.dataset.name;
    document.getElementById(this.data.get("hiddenField")).value =
      e.target.dataset.id;
    this.fieldTarget.setCustomValidity("");
    this.closeDropdown();
  }

  closeDropdown() {
    this.resultTarget.classList.remove("autocomplete__result--active");
  }

  async handleQuery(query) {
    const data = await fetch(
      `${this.data.get("queryUrl")}${
        this.data.get("conjunction") || "&"
      }q=${query}`
    );
    const result = await data.json();
    return result;
  }

  async handleSetData(query) {
    const res = await fetch(
      `${this.data.get("setDataUrl")}&registered_hospital[code]=${query}`
    );
    const result = await res.text();
    return result;
  }

  async handleSetRegisteredHospitalData() {
    const res = await fetch(`${this.data.get("setDataUrl")}`);
    const result = await res.text();
    return result;
  }

  executeCode(code) {
    eval(code);
  }
}
