import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.selectAlert();
  }

  selectAlert() {
    alert({
      title: I18n.t("kpi.please_select"),
      type: "alert",
      style: "danger",
    });
  }
}
