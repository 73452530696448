import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'submit', 'registeredSize', 'actualSize', 'trixField']

  connect() {
    let formElement = this.element.elements
    this.invalid = 0

    for (const element of formElement) {
      if (element.required) {
        element.addEventListener('change', () => {
          this.validation(element)
        })
      }
    }
  }

  addInvalidMessage(element, message) {
    let parentElement = element.parentElement
    parentElement.classList.add('input--error')

    if (!parentElement.querySelector('.input__message')) {
      element.insertAdjacentHTML('afterend', `<div class="input__message">${message}</div>`)
    } else {
      parentElement.querySelector('.input__message').innerHTML = message
    }
  }

  removeInvalidMessage(element) {
    let parentElement = element.parentElement
    parentElement.classList.remove('input--error')

    if (parentElement.querySelector('.input__message')) {
      parentElement.removeChild(parentElement.querySelector('.input__message'))
    }
  }

  validateChecker(element) {
    if (element.classList.contains('form-control')) {
      this.validateDatePicker(element)
    } else if (element.required) {
      this.validation(element)
    }
  }

  validation(element) {
    if (!element.validity.valid) {
      this.addInvalidMessage(element, I18n.t("form.enter_value"))
    } else {
      this.removeInvalidMessage(element)
    }
  }

  validateDatePicker(element) {
    let parentElement = element.parentElement
    let inputElement = parentElement.querySelector('.flatpickr-input')

    if (inputElement.required && !inputElement.value) {
      this.addInvalidMessage(element, I18n.t("form.select_date"))
    } else {
      this.removeInvalidMessage(element)
    }
  }

  formValidation(e) {
    e.preventDefault()

    let formElement = this.element.elements
    let el = Object.entries(formElement).map(i => {
      if (i[1] && i[1].validity && !i[1].validity.valid) {
        return i[1]
      } else if (i[1].className == 'flatpickr-input' && i[1].required && i[1].value == '') {
        return i[1].nextElementSibling
      }
    })

    let invalidElement = el.filter(Boolean)
    this.invalid = invalidElement.length

    if (invalidElement.length !== 0) {
      invalidElement[0].focus()
    }

    for (const element of formElement) {
      this.validateChecker(element)
    }

    if (this.formTarget.checkValidity() && this.invalid == 0) {
      this.formTarget.submit()
    }
  }

  fieldValidation(e) {
    e.preventDefault()

    this.submitTarget.disabled = false
    let formElement = this.element.elements
    let activeElement = document.activeElement

    for (const element of formElement) {
      if (element === activeElement) {
        this.validateChecker(element)
      }
    }
  }

  trixFieldValidation() {
    this.submitTarget.disabled = false

    if (this.hasTrixFieldTarget) {
      if (!this.trixFieldTarget.value) {
        this.addInvalidMessage(this.trixFieldTarget, I18n.t("form.enter_value"))
        this.trixFieldTarget.focus()
      } else {
        this.removeInvalidMessage(this.trixFieldTarget)
      }
    }
  }

  trixFormValidation(e) {
    e.preventDefault()

    if (this.hasTrixFieldTarget) {
      this.trixFieldValidation()

      if (this.trixFieldTarget.value) {
        this.formTarget.submit()
      }
    }
  }
}
