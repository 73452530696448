export const apiHarder = () => {
  return apiHarderCustom({});
};

export const apiHarderCustom = (otherParams) => {
  return {
    ...otherParams,
    "X-Requested-With": "XMLHttpRequest",
    "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
  };
};
