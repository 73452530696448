import { Controller } from "stimulus"
import { getCookie, setCookie } from "../helper"

export default class extends Controller {
 
  static targets = ["modal" ]
  connect () {
    // if (typeof getCookie("pdpa") === 'undefined') {
      this.modalTarget.style.display = "block";
      
    // } 
  }
  handleCloseModal() {
    this.modalTarget.style.display = "none";
    // setCookie("pdpa", true) 
  }  
}



