export const Alert = ({ title, content, type, style }) => {
  document.querySelector("[data-alert-header]").textContent = title
  document.querySelector("[data-alert-content]").textContent = content
  document.querySelector("[data-alert]").classList.add("dimmer--active-alert")
  document.querySelector('.main-layout').classList.add('main-layout--disabled-scroll')
  document.querySelector("[data-alert-cancel]").classList.remove("btn--hide")

  if (type === 'alert') {
    document.querySelector("[data-alert-cancel]").classList.add("btn--hide")
  }

  if (style === "success") {
    successAlert()
  }

  if (style === "info") {
    infoAlert()
  }

  if (style === "warning") {
    warningAlert()
  }

  if (style === "danger") {
    dangerAlert()
  }

  const promise = new Promise((resolve, reject) => {
    document.querySelector("[data-alert-confirm]").addEventListener("click", () => {
      closeAlert()
      resolve({ value: true })
    })
    document.querySelector("[data-alert-cancel]").addEventListener("click", () => {
      closeAlert()
      reject({ value: false })
    })
  })

  return promise
}

const successAlert = () => {
  document.querySelector("[data-alert-confirm]").classList.add(`btn--success`)
  let iconContainer = document.querySelector("[data-alert-icon]")
  let iconBadge = iconContainer.querySelector(".badge")
  iconContainer.classList.add("alert__icon--active")
  iconBadge.classList.add("badge--success")
  iconBadge.innerHTML = "<i class='fas fa-check'></i>"
}

const infoAlert = () => {
  document.querySelector("[data-alert-confirm]").classList.add(`btn--info`)
  let iconContainer = document.querySelector("[data-alert-icon]")
  let iconBadge = iconContainer.querySelector(".badge")
  iconContainer.classList.add("alert__icon--active")
  iconBadge.classList.add("badge--info")
  iconBadge.innerHTML = "<i class='fas fa-info'></i>"
}

const warningAlert = () => {
  document.querySelector("[data-alert-confirm]").classList.add(`btn--warning`)
  let iconContainer = document.querySelector("[data-alert-icon]")
  let iconBadge = iconContainer.querySelector(".badge")
  iconContainer.classList.add("alert__icon--active")
  iconBadge.classList.add("badge--warning")
  iconBadge.innerHTML = "<i class='fas fa-exclamation'></i>"
}

const dangerAlert = () => {
  document.querySelector("[data-alert-confirm]").classList.add(`btn--danger`)
  let iconContainer = document.querySelector("[data-alert-icon]")
  let iconBadge = iconContainer.querySelector(".badge")
  iconContainer.classList.add("alert__icon--active")
  iconBadge.classList.add("badge--danger")
  iconBadge.innerHTML = "<i class='fas fa-times'></i>"
}

const closeAlert = () => {
  document.querySelector("[data-alert]").classList.remove("dimmer--active-alert")
  document.querySelector('.main-layout').classList.remove('main-layout--disabled-scroll')
  document.querySelector("[data-alert-confirm]").classList.remove(`btn--success`)
  document.querySelector("[data-alert-confirm]").classList.remove(`btn--info`)
  document.querySelector("[data-alert-confirm]").classList.remove(`btn--warning`)
  document.querySelector("[data-alert-confirm]").classList.remove(`btn--danger`)
  let iconContainer = document.querySelector("[data-alert-icon]")
  let iconBadge = iconContainer.querySelector(".badge")
  iconContainer.classList.remove("alert__icon--active")
  iconBadge.classList.remove("badge--success")
  iconBadge.classList.remove("badge--info")
  iconBadge.classList.remove("badge--warning")
  iconBadge.classList.remove("badge--danger")
}
