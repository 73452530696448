export default class AppearanceObserver {
  constructor(delegate, element) {
    this.started = false;
    this.intersect = (entries) => {
      const lastEntry = entries.slice(-1)[0];
      if (
        lastEntry === null || lastEntry === void 0
          ? void 0
          : lastEntry.isIntersecting
      ) {
        this.delegate.elementAppearedInViewport(this.element);
      }
    };
    this.delegate = delegate;
    this.element = element;
    this.intersectionObserver = new IntersectionObserver(
      this.intersect,
      this.options
    );
  }
  start() {
    if (!this.started) {
      this.started = true;
      this.intersectionObserver.observe(this.element);
    }
  }
  stop() {
    if (this.started) {
      this.started = false;
      this.intersectionObserver.unobserve(this.element);
    }
  }
  get options() {
    return {
      rootMargin: "0px 0px 300px 0px",
      threshold: 0,
    };
  }
}
