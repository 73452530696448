import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["container", "link"]

  connect() {
    if (this.containerTarget.getBoundingClientRect().top > (window.innerHeight / 2)) {
      this.containerTarget.classList.add("popup--top")
    } else {
      this.containerTarget.classList.remove("popup--top")
    }

    // document.addEventListener("click", function (event) {
    //   if (that.containerTarget.classList.contains("popup--active")) {
    //     if (!that.containerTarget.contains(event.target)) {
    //       that.containerTarget.classList.remove("popup--active")

    //       if (window.innerWidth <= 576) {
    //         setTimeout(() => {
    //           document.querySelector('.fade').classList.remove('fade--active')
    //           document.querySelector('.main-layout').classList.remove('main-layout--disabled-scroll')
    //         })
    //       }
    //     }
    //   }
    // })
  }

  handleOpenPopup() {
    let eventY = event.y
    let eventX = event.x
    let currentY = event.view.innerHeight
    let currentX = event.view.innerWidth

    if (!((currentY / 2) > eventY)) {
      this.containerTarget.classList.add("popup--active")
      this.containerTarget.classList.add("popup--top")
    } else {
      this.containerTarget.classList.add("popup--active")
      this.containerTarget.classList.remove("popup--top")
    }

    if (eventX < (currentX / 2)) {
      this.containerTarget.classList.add("popup--active")
      this.containerTarget.classList.add("popup--right")
    } else {
      this.containerTarget.classList.add("popup--active")
      this.containerTarget.classList.remove("popup--right")
    }

    if (window.innerWidth <= 576) {
      this.containerTarget.classList.add("popup--active")
      this.containerTarget.classList.add("popup--center")
      setTimeout(() => {
        document.querySelector('.fade').classList.add('fade--active')
        document.querySelector('.main-layout').classList.add('main-layout--disabled-scroll')
      })
    }
  }

  handleClosePopup() {
    this.containerTarget.classList.remove("popup--active")
    this.containerTarget.classList.remove("popup--top")

    if (window.innerWidth <= 576) {
      this.containerTarget.classList.remove("popup--active")
      this.containerTarget.classList.remove("popup--center")
      this.containerTarget.classList.remove("popup--top")
      setTimeout(() => {
        document.querySelector('.fade').classList.remove('fade--active')
        document.querySelector('.main-layout').classList.remove('main-layout--disabled-scroll')
      })
    }
  }
}
