import Flatpickr from "stimulus-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import { flatpickrHelper } from "../helper";
import { TH } from "flatpickr/dist/l10n/th.js";

export default class extends Flatpickr {
  initialize() {
    this.initLocaleConfig(document.documentElement.lang);
    this.config = {
      locale: this.locale,
      altFormat: "F-j-Y",
      altInput: true,
      allowInput: this.data.get("allowInput"),
      plugins: this.pluginsConfig(),
    };

    if (this.hasInstanceTarget) {
      this.instanceTarget.addEventListener("change", () => {
        const { fp, data, calendarContainerTarget } = this;
        const fakeYearElement = calendarContainerTarget.querySelector(
          ".flatpickr__fake-value"
        );

        if (data.get("isMonthSelect")) {
          if (fp.selectedDates[0]) {
            fp.altInput.value = flatpickrHelper.toBuddhistMonthYear(
              fp.selectedDates[0]
            );
          }
        } else {
          fp.setDate(fp.input.value);

          if (fp.input.value) {
            fp.altInput.value = flatpickrHelper.toBuddhistYear(
              fp.input.value,
              "LL"
            );
          } else {
            fp.altInput.value = null;
          }
        }

        if (fakeYearElement) {
          fakeYearElement.textContent = flatpickrHelper.handleYear(fp.currentYearElement.value);
        }
      });
    }
  }

  ready(selectedDates, dateStr, instance) {
    flatpickrHelper.createFakeYearElement(instance);

    if (this.data.get("isMonthSelect")) {
      if (instance.input.value) {
        instance.altInput.value = flatpickrHelper.toBuddhistMonthYear(
          selectedDates[0]
        );
      }
    } else {
      if (instance.config.mode === "range") {
        if (selectedDates[0]) {
          instance.altInput.value = flatpickrHelper.toBuddhistYear(
            selectedDates[0],
            "LL"
          );
        }
        if (selectedDates[1]) {
          instance.altInput.value = `${flatpickrHelper.toBuddhistYear(
            selectedDates[0],
            "LL"
          )} ถึง ${flatpickrHelper.toBuddhistYear(selectedDates[1], "LL")}`;
        }
      } else {
        if (instance.input.value) {
          instance.altInput.value = flatpickrHelper.toBuddhistYear(
            selectedDates[0],
            "LL"
          );
        }
      }
    }
  }

  change(selectedDates, dateStr, instance) {
    const { data } = this;
    const isMonthRange = data.get("isMonthRange");
    const rangeMode = data.get("rangeMode");
    const secondInstance = data.get("secondElement");

    flatpickrHelper.validateMonthRange(
      instance,
      isMonthRange,
      rangeMode,
      secondInstance
    );
  }

  yearChange(selectedDates, dateStr, instance) {
    instance.calendarContainer.querySelector(
      ".flatpickr__fake-value"
    ).textContent = flatpickrHelper.handleYear(instance.currentYearElement.value);
  }

  pluginsConfig() {
    const plugins = [];

    if (this.data.get("isMonthSelect")) {
      const monthSelect = new monthSelectPlugin({
        shorthand: true,
        dateFormat: "Y-m-d",
        altFormat: "F Y",
      });
      plugins.push(monthSelect);
    }

    return plugins;
  }

  initLocaleConfig(initLocale) {
    const {locale, isTH} = flatpickrHelper.localeConfig(initLocale);
    this.locale = locale;
    this.isTH = isTH;
  }
}
