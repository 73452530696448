export const eventCreator = (eventName, eventDetail) => {
  const event = new CustomEvent(eventName, {
    detail: eventDetail,
  });

  document.dispatchEvent(event);
};

export const changeElementsDisplayStyle = (elements, display) => {
  elements.forEach((item) => {
    item.style.display = display;
  });
};

export const disableInput = (input, value) => {
  if (value !== "") {
    input.disabled = true;
  } else {
    input.disabled = false;
  }
};
