import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['pinned', 'pinnedCarousel', 'categoryDropdown', 'ended', 'endDate']

  connect() {
    this.pinnedTarget.addEventListener('change', () => {
      if (this.pinnedTarget.checked) {
        this.pinnedCarouselTarget.checked = false
      }
    })

    this.pinnedCarouselTarget.addEventListener('change', () => {
      if (this.pinnedCarouselTarget.checked) {
        this.pinnedTarget.checked = false
      }
    })

    this.handleTogglePinnedCheckbox(this.categoryDropdownTarget.value)
    this.handleToggleEndDate(this.endedTarget.checked)
  }

  handleSelectCategoryDropdown(e) {
    this.handleTogglePinnedCheckbox(e.target.value)
  }

  handleTogglePinnedCheckbox(value) {
    if (value === 'about_us') {
      this.pinnedTarget.disabled = true
      this.pinnedCarouselTarget.disabled = true
      this.pinnedTarget.checked = false
      this.pinnedCarouselTarget.checked = false
    } else {
      this.pinnedTarget.disabled = false
      this.pinnedCarouselTarget.disabled = false
    }
  }

  handleCheckEnded(e) {
    this.handleToggleEndDate(e.target.checked)
  }

  handleToggleEndDate(value) {
    if (value) {
      this.endDateTarget.style.display = 'block' 
    } else {
      this.endDateTarget.style.display = 'none'
    }
  }
}
