import { Controller } from 'stimulus'
import {clearSessionStorage, checkListsSessionToArray} from '../packs/utils/session'
export default class extends Controller {

  handleClearSessionStorage(e) { 
    console.log('clear_session_storage')
    clearSessionStorage();
  }

  handleSubmitERP(e) {
    const btn = e.target
    const btnAll = document.getElementById("btnERPSelectedAllExport")
    console.log('clear_session_storage')
    const form = document.getElementById("erpForm")
    const ids = document.getElementById("ids")
    ids.value = checkListsSessionToArray('erpCheckLists').join(',')
    form.submit()
    clearSessionStorage();
    btn.disabled = true;
    btnAll.disabled = true;
  }

  handleSubmitERPAll(e) {
    const btn = e.target
    const btnS = document.getElementById("btnERPSelectedExport")
    clearSessionStorage();
    btn.disabled = true;
    btnS.disabled = true;
  }
}
