import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['list']
  connect() {
  }
  handleChange(e) {
    const refCode = e.target.dataset.refcode;
    if (this.hasListTarget && refCode) {
      this.listTarget.querySelectorAll(`[data-refcode="${refCode}"]`).forEach((element) => {
        if (e.target !== element && element.value !== e.target.value) {
          element.value = e.target.value
          element.dispatchEvent(new Event("keyup"))
        }
      })
    }
  }
}
