function removeA(arr) {
  var what, a = arguments, L = a.length, ax;
  while (L > 1 && arr.length) {
      what = a[--L];
      while ((ax= arr.indexOf(what)) !== -1) {
          arr.splice(ax, 1);
      }
  }
  return arr;
}


export function checkListsSessionToArray(sessionName) {
  return sessionStorage.getItem(sessionName)
    ? (sessionStorage.getItem(sessionName) || "").split(",")
    : [];
}

export function addCheckLists(sessionName, data) {
  if (sessionStorage.getItem(sessionName) !== '' && sessionStorage.getItem(sessionName) !== null) {
    sessionStorage.setItem(sessionName, [data].concat(sessionStorage.getItem(sessionName)));
  } else {
    sessionStorage.setItem(sessionName, [data]);
  }
}

export function addAndValidateCheckLists(sessionName, data) {
  if (sessionStorage.getItem(sessionName) !== '' && sessionStorage.getItem(sessionName) !== null) {
    if (!checkListsSessionToArray(sessionName).find((element) => element === data)) {
      sessionStorage.setItem(sessionName, [data].concat(sessionStorage.getItem(sessionName)));
    }
  } else {
    sessionStorage.setItem(sessionName, [data]);
  }
} 

export function deleteCheckLists(sessionName, data) {
  if (sessionStorage.getItem(sessionName) !== '' && sessionStorage.getItem(sessionName) !== null) {
    sessionStorage.setItem(sessionName, removeA(checkListsSessionToArray(sessionName), data));
  } else {
    sessionStorage.setItem(sessionName, []);
  }
}

// export function removeCheckLists(sessionName, data) {
//   const checkLists = checkListsSessionToArray(sessionName);
//   let index = checkLists.indexOf(data.toString());
//   if (index > -1) {
//     checkLists.splice(index, 1);
//   }
//   sessionStorage.setItem(sessionName, checkLists);
// }

// export function checkSessionToChangeLists(sessionName, checkboxName) {
//   jQuery.each($(checkboxName), function () {
//     let index = checkListsSessionToArray(sessionName).indexOf($(this).val());
//     if (index > -1) {
//       $(this).prop("checked", true);
//     }
//   });
// }

export function clearSessionStorage() {
  sessionStorage.clear();
}
