import { Controller } from "stimulus"

export default class extends Controller {
  // static targets = [ 'infoPanel' ]
  connect() {
    this.url = this.data.get("url")
  }

  change(event) {
    let id = event.target.value

    let goTo = `${this.url}/${id}`
    if (location.search != "")
      goTo = goTo.concat(`${location.search}`)
  
    Turbo.visit(goTo)
  }
}
