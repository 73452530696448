export default class YoutubeEmbedded {
  extend (parent) {
    const buttonHTML =
    '<button type="button" class="trix-button" data-trix-attribute="embed" data-trix-action="embed" title="Embed" tabindex="-1">Youtube Embed</button>'
    const buttonGroup = parent.toolbarElement.querySelector(
      ".trix-button-group--text-tools"
    )
    const dialogHml = 
      `<div class="trix-dialog trix-dialog--link" data-trix-dialog="embed" data-trix-dialog-attribute="embed">
        <div class="trix-dialog__link-fields">
          <input type="text" name="embed" class="trix-input trix-input--dialog" placeholder="Paste your youtube video url" aria-label="embed code" required="" data-trix-input="" disabled="disabled">
          <div class="trix-button-group">
            <input type="button" class="trix-button trix-button--dialog" data-trix-custom="add-embed" value="Add">
          </div>
        </div>
      </div>`
    const dialogGroup = parent.toolbarElement.querySelector(".trix-dialogs")
    buttonGroup.insertAdjacentHTML("beforeend", buttonHTML)
    dialogGroup.insertAdjacentHTML("beforeend", dialogHml)

    this.addEmbedEventListener()
  }

  addEmbedEventListener () {
    document
      .querySelector('[data-trix-action="embed"]')
      .addEventListener("click", event => {
        const dialog = document.querySelector('[data-trix-dialog="embed"]');
        const embedInput = document.querySelector('[name="embed"]');
        if (event.target.classList.contains("trix-active")) {
          event.target.classList.remove("trix-active");
          dialog.classList.remove("trix-active");
          delete dialog.dataset.trixActive;
          embedInput.setAttribute("disabled", "disabled");
        } else {
          event.target.classList.add("trix-active");
          dialog.classList.add("trix-active");
          dialog.dataset.trixActive = "";
          embedInput.removeAttribute("disabled");
          embedInput.focus();
        }
      })
  
    document
      .querySelector('[data-trix-custom="add-embed"]')
      .addEventListener("click", event => {
        console.log('dispath event')
        document.dispatchEvent(new CustomEvent("add-embed", { 
          bubbles: true,
          detail: { link: () => document.querySelector('[name="embed"]').value }
        }))
      })
  }
}
