import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["skeleton"];

  connect() {
    this.observeSkeleton();
  }

  observeSkeleton() {
    let options = {
      root: null,
      rootMargin: "300px",
      threshold: 0,
    };

    let observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          console.log(
            entry.target.parentElement.parentElement.parentElement.setAttribute(
              "loading",
              "eager"
            )
          );
        }
      });
    }, options);

    this.skeletonTargets.forEach((item) => {
      observer.observe(item);
    });
  }
}
