import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["link", "content"]

  connect() {
    this.handleActiveLink(this.data.get("default-url"), this.data.get("default-tab"))
  }

  handleClickLink(event) {
    const { url, index } = event.target.dataset
    this.handleActiveLink(url, index)
  }

  handleActiveLink(url, index) {
    this.linkTargets.forEach(el => {
      el.classList.toggle("tab__menu__item--active", index == el.dataset.index)
    })

    this.handleGetNews(url).then(data => this.render(data))
  }

  async handleGetNews(url) {
    this.render("<div class='loader'></div>")
    const res = await fetch(url)
    let data = await res.text()
    return data
  }

  render(html) {
    this.contentTarget.textContent = ""
    this.contentTarget.insertAdjacentHTML("afterbegin", html)
  }
}
