import { Controller } from 'stimulus'
import debounce from 'debounce'

export default class extends Controller {
  static targets = ['input', 'form', 'submit', 'select']

  connect() {
    if (this.hasInputTarget) {
      this.inputTarget.addEventListener('keyup', debounce(() => {
        this.submitTarget.click()
      }, 500))
    }

    if (this.hasSelectTarget) {
      this.selectTargets.forEach(item => {
        item.addEventListener('change', debounce(() => {
          this.submitTarget.click()
          this.loading()
        }, 500))
      })
    }

    if (this.hasFormTarget) {
      this.formTarget.addEventListener('keypress', (e) => {
        if (e.which === 13 && e.target.type !== 'textarea') {
          e.preventDefault()
        }
      })
    }
  }

  loading() {
    var elements = document.querySelector(this.data.get('elementsLoading'))
    if (elements) {
      elements.innerHTML = `<div class='loader'></div>`
    }
  }
}
