import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    this.addScript()
  }

  addScript () {
    window.$_Tawk = undefined;
    // window.Tawk_API = undefined;
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.src='https://embed.tawk.to/61ef692eb9e4e21181bbb8b7/1fq7j5o8a';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
    })();

  }
}
