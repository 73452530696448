import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['query', 'list']

  connect() {
    if (!Boolean(this.data.get('hide'))) {
      document.querySelectorAll('.treeview').forEach(el => {
        el.classList.add('treeview--active')
      })

      document.querySelectorAll('.treeview__item').forEach(el => {
        el.classList.add('treeview__item--active')
      })
    }

    document.addEventListener('keypress', (e) => {
      if (e.which === 13 && e.target.type !== 'textarea') {
        e.preventDefault()
      }
    })
  }

  rfSearchKpi(ul, query, isParentDisplay) {
    let stateDisplay = isParentDisplay
    const childUl = ul.childNodes
    let last

    for (var i = 0; i < childUl.length; ++i) {
      const li = childUl[i]
      if (li.nodeName === 'LI') {
        const isDisplay = isParentDisplay || li.getElementsByClassName("link")[0].textContent.toLowerCase().includes(query.toLowerCase())
        const isChildDisplay = li.getElementsByTagName("ul")[0] ? this.rfSearchKpi(li.getElementsByTagName("ul")[0], query, isDisplay) : isDisplay;
        li.style.display = isChildDisplay ? "" : "none"

        if (childUl[i].style.display != 'none') {
          last = childUl[i]
        }

        if (last) {
          last.classList.remove('treeview__item--last')
        }

        stateDisplay = stateDisplay || isChildDisplay
      }
    }

    if (last) {
      last.classList.add('treeview__item--last')
    }

    return stateDisplay;
  }

  keyup_query(e) {
    this.rfSearchKpi(this.listTarget, e.target.value, false)
  }
}
