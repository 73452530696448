import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'container' ]
  
  handleOpenModal = () => {
    this.containerTarget.classList.add('modal-container--active')
    document.querySelector('.home-layout').classList.add('home-layout--disabled-scroll')
  }

  handleCloseModal = () => {
    this.containerTarget.classList.remove('modal-container--active')
    document.querySelector('.home-layout').classList.remove('home-layout--disabled-scroll')
  }
}
