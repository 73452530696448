import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'type', 'submit', 'all', 'retain', 'abandon']

  connect() {
    this.hasAllTarget && this.allTarget.classList.add('tab__menu__item--active')

    this.formTarget.addEventListener('change', () => {
      this.loading()
      this.submitTarget.click()
    })
  }

  allHistoryType() {
    this.allTarget.classList.add('tab__menu__item--active')
    this.retainTarget.classList.remove('tab__menu__item--active')
    this.abandonTarget.classList.remove('tab__menu__item--active')
    this.typeTarget.value = 'all'
    this.loading()
    this.submitTarget.click()
  }

  retainHistoryType() {
    this.allTarget.classList.remove('tab__menu__item--active')
    this.retainTarget.classList.add('tab__menu__item--active')
    this.abandonTarget.classList.remove('tab__menu__item--active')
    this.typeTarget.value = 'retain'
    this.loading()
    this.submitTarget.click()
  }

  abandonHistoryType() {
    this.allTarget.classList.remove('tab__menu__item--active')
    this.retainTarget.classList.remove('tab__menu__item--active')
    this.abandonTarget.classList.add('tab__menu__item--active')
    this.typeTarget.value = 'abandon'
    this.loading()
    this.submitTarget.click()
  }

  loading() {
    document.querySelector('#kpi_history_content').innerHTML = `<div class='loader'></div>`
  }
}
