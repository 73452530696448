import { Controller } from "stimulus"
import { tns } from "tiny-slider/src/tiny-slider"

export default class extends Controller {
  static targets = ["slider", "controls"]

  connect() {
    tns({
      container: this.sliderTarget,
      items: 1,
      slideBy: 1,
      autoplay: true,
      autoplayButtonOutput: false,
      mouseDrag: true,
      controls: true,
      controlsPosition: "bottom",
      nav: true,
      navPosition: "bottom",
      controlsContainer: this.controlsTarget,
    });
  }
}
