import { Controller } from 'stimulus'
import {clearSessionStorage, deleteCheckLists, checkListsSessionToArray, addAndValidateCheckLists} from '../packs/utils/session'
const nameState = "erpCheckLists"
export default class extends Controller {
  static targets = ['masterCheckbox', 'checkbox']
  
  connect() {
    checkListsSessionToArray(nameState).forEach((erpId) => {
      var element = document.getElementById(`erp_checkbox_${erpId}`)
      if (element) {
        element.checked = true
      }
      this.onCheck()
    })
    this.handleCountERP()
  }

  onMasterCheck(e) {
    const checked = e.target.checked
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = checked
      var id = checkbox.getAttribute('data-id')
      console.log('1: id', id)
      if (checked) {
        addAndValidateCheckLists(nameState, id);
      } else {
        deleteCheckLists(nameState, id);
      }
    })

    this.handleCountERP()
  }

  onCheck(e) {
    const checkStatus = this.checkboxTargets.map(check => check.checked)
    const every = checkStatus.every(Boolean)
    const some = checkStatus.some(Boolean)
    var id =  e && e.target.getAttribute('data-id')
    ////////////////////////////////////////////////////////////////////////
    if (e && e.target.checked) { 
      addAndValidateCheckLists(nameState, id);
    } else if (e) {
      deleteCheckLists(nameState, id);
    }

    this.handleCountERP()
    ////////////////////////////////////////////////////////////////////////
    this.masterCheckboxTarget.checked = every
    this.masterCheckboxTarget.indeterminate = !every && some
  }

  handleCountERP() {
    const btn = document.getElementById("btnERPSelectedExport")
    const size = checkListsSessionToArray(nameState).length
    if (size > 0) {
      btn.innerHTML = `${I18n.t("erp.selected_export")} ${size} ${I18n.t("erp.record")}`;
      btn.disabled = false;
    } else {
      btn.disabled = true;
      btn.innerHTML = I18n.t("erp.selected_export")
    }    
  }


  handleClearERPClick() {
    clearSessionStorage();
  }
}
