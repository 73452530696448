import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['link']

  connect() {
    this.linkTargets.forEach(link => {
      if (link.textContent === this.data.get('activeYear')) {
        link.classList.add('sidebar__menu__item--active')
      } else {
        link.classList.remove('sidebar__menu__item--active')
      }
    })
  }

  handleActiveLink(e) {
    this.linkTargets.forEach(link => {
      link.classList.remove('sidebar__menu__item--active')

      if (link.dataset.index = e.target.dataset.index) {
        e.target.classList.add('sidebar__menu__item--active')
      }
    })
  }
}
