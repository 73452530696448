import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "canvas",
    "kpiValue",
    "lowerLimit",
    "kpiLevel2",
    "kpiLevel3",
    "kpiLevel4",
    "kpiLevel5",
    "upperLimit",
    "kpiFormulaType",
    "kpiInput1",
    "kpiInput2",
  ];

  connect() {
    const ctx = this.canvasTarget.getContext("2d");
    const canvasOffset = 50;
    const canvasMax = 250;
    const indicatorBarWidth = 50;
    const minWidth = 50
    let boxPosition = null
    let boxWidth = null

    this.have_divider = !(this.data.get('formula') === '1')
    this.dividend = this.hasKpiInput1Target ? this.kpiInput1Target.textContent : null
    this.divider = this.hasKpiInput2Target ? this.kpiInput2Target.textContent : null
    this.result = this.have_divider ? (this.dividend / this.divider) * 100 : this.dividend || 0

    const value = this.result !== "" && Number(this.result).toFixed(2); // ค่า value ของ kpi
    const chartType = this.kpiFormulaTypeTarget.value !== "" ? Number(this.kpiFormulaTypeTarget.value) : null; // ประเภทของ kpi จากน้อยไปมาก หรือ มากไปน้อย
    const lowerLimit = this.lowerLimitTarget.value !== "" ? Number(this.lowerLimitTarget.value) : null; // ค่าของล่างสุด
    const kpiLevel2 = this.kpiLevel2Target.value !== "" ? Number(this.kpiLevel2Target.value) : null;
    const kpiLevel3 = this.kpiLevel3Target.value !== "" ? Number(this.kpiLevel3Target.value) : null;
    const kpiLevel4 = this.kpiLevel4Target.value !== "" ? Number(this.kpiLevel4Target.value) : null;
    const kpiLevel5 = this.kpiLevel5Target.value !== "" ? Number(this.kpiLevel5Target.value) : null;
    const upperLimit = this.upperLimitTarget.value !== "" ? Number(this.upperLimitTarget.value) : null; // ค่าขอบบนสุด

    let indicatorStyle = null; // object ที่เก็บค่า position และค่าสี ตาม kpi type
    let lightColor = null; // สีกล่องข้อความ
    let darkColor = null; // สีค่า value
    let valuePosition = null; // ค่าตำแหน่งแกน x ของ kpi value

    const redColor = {
      base: "#FC8181",
      light: "#FED7D7",
      dark: "#C53030",
    };

    const orangeColor = {
      base: "#F6AD55",
      light: "#FEEBC8",
      dark: "#C05621",
    };

    const yellowColor = {
      base: "#F6E05E",
      light: "#FEFCBF",
      dark: "#B7791F",
    };

    const blueColor = {
      base: "#4B8DFC",
      light: "#ADCAFC",
      dark: "#0A3E96",
    };

    const greenColor = {
      base: "#68D391",
      light: "#C6F6D5",
      dark: "#2F855A",
    };

    const lessBetterIndicator = {
      level1: {
        position: 50,
        color: greenColor,
      },
      level2: {
        position: 100,
        color: blueColor,
      },
      level3: {
        position: 150,
        color: yellowColor,
      },
      level4: {
        position: 200,
        color: orangeColor,
      },
      level5: {
        position: 250,
        color: redColor,
      },
    };

    const moreBetterIndicator = {
      level1: {
        position: 50,
        color: redColor,
      },
      level2: {
        position: 100,
        color: orangeColor,
      },
      level3: {
        position: 150,
        color: yellowColor,
      },
      level4: {
        position: 200,
        color: blueColor,
      },
      level5: {
        position: 250,
        color: greenColor,
      },
    };

    if (chartType === 1) {
      indicatorStyle = moreBetterIndicator;
    } else {
      indicatorStyle = lessBetterIndicator;
    }

    // set สีให้กล่องข้อความตาม value
    if (value < kpiLevel2) {
      lightColor = indicatorStyle.level1.color.light;
      darkColor = indicatorStyle.level1.color.dark;
      valuePosition = indicatorStyle.level1.position + 25
    } else if (value < kpiLevel3) {
      lightColor = indicatorStyle.level2.color.light;
      darkColor = indicatorStyle.level2.color.dark;
      valuePosition = indicatorStyle.level2.position + 25
    } else if (value < kpiLevel4) {
      lightColor = indicatorStyle.level3.color.light;
      darkColor = indicatorStyle.level3.color.dark;
      valuePosition = indicatorStyle.level3.position + 25
    } else if (value < kpiLevel5) {
      lightColor = indicatorStyle.level4.color.light;
      darkColor = indicatorStyle.level4.color.dark;
      valuePosition = indicatorStyle.level4.position + 25
    } else if (value >= kpiLevel5) {
      lightColor = indicatorStyle.level5.color.light;
      darkColor = indicatorStyle.level5.color.dark;
      valuePosition = indicatorStyle.level5.position + 25
    }

    if (lowerLimit || upperLimit) {
      if (value < lowerLimit) {
        valuePosition = 37.5
        ctx.fillStyle = indicatorStyle.level1.color.base;
        ctx.fillRect((indicatorStyle.level1.position) - (indicatorBarWidth / 2), 50, indicatorBarWidth / 2, 12);
      } else if (value > upperLimit) {
        valuePosition = 312.5
        ctx.fillStyle = indicatorStyle.level5.color.base;
        ctx.fillRect(300, 50, indicatorBarWidth / 2, 12);
      }
    }

    // วาดกล่องของแต่ละสี
    ctx.fillStyle = indicatorStyle.level1.color.base;
    ctx.fillRect(indicatorStyle.level1.position, 50, indicatorBarWidth, 12);
    ctx.fillStyle = indicatorStyle.level2.color.base;
    ctx.fillRect(indicatorStyle.level2.position, 50, indicatorBarWidth, 12);
    ctx.fillStyle = indicatorStyle.level3.color.base;
    ctx.fillRect(indicatorStyle.level3.position, 50, indicatorBarWidth, 12);
    ctx.fillStyle = indicatorStyle.level4.color.base;
    ctx.fillRect(indicatorStyle.level4.position, 50, indicatorBarWidth, 12);
    ctx.fillStyle = indicatorStyle.level5.color.base;
    ctx.fillRect(indicatorStyle.level5.position, 50, indicatorBarWidth, 12);

    // set style font
    ctx.font = "16px Arial";
    ctx.fillStyle = "#777";
    ctx.textAlign = "center";

    // แสดงค่าขอบของแต่ละสี
    lowerLimit !== null &&
      ctx.fillText(lowerLimit, indicatorStyle.level1.position, 82);
    kpiLevel2 !== null &&
      ctx.fillText(kpiLevel2, indicatorStyle.level2.position, 82);
    kpiLevel3 !== null &&
      ctx.fillText(kpiLevel3, indicatorStyle.level3.position, 82);
    kpiLevel4 !== null &&
      ctx.fillText(kpiLevel4, indicatorStyle.level4.position, 82);
    kpiLevel5 !== null &&
      ctx.fillText(kpiLevel5, indicatorStyle.level5.position, 82);
    upperLimit !== null &&
      ctx.fillText(
        upperLimit,
        indicatorStyle.level5.position + canvasOffset,
        82
      );

    if (value) {
      let txt = value;
      let txtWidth = ctx.measureText(txt).width;

      function roundRect(ctx, x, y, width, height, radius, fill, stroke) {
        if (typeof stroke === "undefined") {
          stroke = true;
        }
        if (typeof radius === "undefined") {
          radius = 5;
        }
        if (typeof radius === "number") {
          radius = {
            tl: radius,
            tr: radius,
            br: radius,
            bl: radius,
          };
        } else {
          var defaultRadius = {
            tl: 0,
            tr: 0,
            br: 0,
            bl: 0,
          };
          for (var side in defaultRadius) {
            radius[side] = radius[side] || defaultRadius[side];
          }
        }
        ctx.beginPath();
        ctx.moveTo(x + radius.tl, y);
        ctx.lineTo(x + width - radius.tr, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
        ctx.lineTo(x + width, y + height - radius.br);
        ctx.quadraticCurveTo(
          x + width,
          y + height,
          x + width - radius.br,
          y + height
        );
        ctx.lineTo(x + radius.bl, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
        ctx.lineTo(x, y + radius.tl);
        ctx.quadraticCurveTo(x, y, x + radius.tl, y);
        ctx.closePath();
        if (fill) {
          ctx.fill();
        }
        if (stroke) {
          ctx.stroke();
        }
      }

      if (txtWidth + 10 < minWidth) {
        boxWidth = minWidth
      } else { 
        boxWidth = txtWidth * 2
      }

      ctx.strokeStyle = lightColor;
      ctx.fillStyle = lightColor;
      boxPosition = valuePosition - (boxWidth / 2)
      roundRect(ctx, boxPosition, 15, boxWidth, 30, 10, true); 

      // วาดสามเหลี่ยมกล่องข้อความ
      ctx.beginPath();
      ctx.moveTo(valuePosition, 55);
      ctx.lineTo(valuePosition + 8, 45);
      ctx.lineTo(valuePosition - 8, 45);
      ctx.closePath();
      ctx.fillStyle = lightColor;
      ctx.fill();

      // แสดงค่า value ในกล่องข้อความ
      ctx.fillStyle = darkColor;
      ctx.font = "20px Arial";
      ctx.fillText(value, valuePosition, 38);
    }
  }
}
